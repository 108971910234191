import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import BtnSetting from '../BtnSetting';
import UserItem from '../UserItem'
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import './Group.scss';
import Swal from "sweetalert2";



export default function Groups({onClose}) {
  const [groupName, setGroupName] = useState('');
  const [users, setUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [, /*selectedUsers*/ setSelectedUsers] = useState([]);
  const [item, setItem] = useState();

  console.log('item', item);
  const handleCreateGroup = () => {
    const selectedUserIds = item.map((user) => user.id);
    let data = {};
    data.name_group = groupName;
    data.user_group = selectedUserIds;
    const urlCreate = `${routes.routes.back_end.admin.users.createGroup.url}`;
    const methodCreate = `${routes.routes.back_end.admin.users.createGroup.method}`;
    ApiService.sendingExpectPutData(urlCreate, methodCreate, data, "user")
      .then((result) => {
        if(result.code !== 200){
          Swal.fire({
            title: "GROUP NOT CREATED!",
            text: "ERROR",
            icon: "error",
            timer: 3500,
            timerProgressBar: true,
          });
          onClose();
        } else {
          Swal.fire({
            title: "GROUP CREATED!",
            text: "SUCCESS",
            icon: "success",
            timer: 3500,
            timerProgressBar: true,
          });
          onClose();
        }  
      })
      .catch(() => {
        console.log("error");
      });
  };

  const checkEmpty = useEffect (() => {
    if (Object.keys(groupName).length === 0) {
      console.log('Object is empty');
    }
    else{
      console.log('not empty')
    }

     
  },[])

  const handleSelectAll = () => {
    const updatedUsers = users.map((user) => ({
      ...user,
      isChecked: !selectAll,
    }));
    setUsers(updatedUsers);
    setSelectAll(!selectAll);
  };

  const handleSelectChange = (select) => {
    setItem(select);
  };

  const handleCancelModal = () => {
    onClose();
  };

  return (
    <div className='Groups'>
      <div className='group-name-wrapper'>
        <label htmlFor='group-name'>
          Group name
        </label>
        <input
          type="text"
          id='group-name'
          placeholder='Group name'
          onChange={(e) => setGroupName(e.target.value)}
        />
      </div>
      <div className='group-title'>
        <span>Select user to add in group</span>
        <span onClick={handleSelectAll}>
          {selectAll ? "Unselect all" : "Select all"}
        </span>
      </div>
      <div className='users-list'>
        <UserItem
            ListUsers={setSelectedUsers}
            selectAll={selectAll}
            onSelectChange={handleSelectChange}
          />
      </div>
      <div className='decision-buttons'>
        <BtnSetting 
          text='Cancel' 
          className="cancel"
          onClick={handleCancelModal} 
        /> 
        <BtnSetting 
          onClick={handleCreateGroup} 
          text='Create Group' 
          className='confirm' 
        /> 
      </div>
    </div>
  )
}
