import React, { useState, useEffect } from "react";
import select_file from "../../../Assets/vectors/banner/select_file.svg";
import icon_doc_name from "../../../Assets/vectors/currency/icon_doc_name.svg";
import gallery from "../../../Assets/vectors/currency/gallery.svg";
import delete_file from "../../../Assets/vectors/currency/delete_file.svg";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import config from "../../../Config/config";
import Swal from "sweetalert2";

const Currency = () => {
  const [file, setFile] = useState(null);
  const [allData, setAllData] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if(!loaded){
      fetchCurrencies();
      setLoaded(true);
    }
  },[loaded])

  function handleChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setFile(file);
    }
  }
  const handleDelete = () => {
    //event.target.files = null;
    //let data = null;
    setFile(null);
    setAllData(null);
  };

  const fetchCurrencies = () => {
    const urlGetCurrency = `${routes.routes.back_end.admin.transactions.currencies.url}`;
    const methodGetCurrency = `${routes.routes.back_end.admin.transactions.currencies.method}`;

    ApiService.fetchApiData(urlGetCurrency, methodGetCurrency, "transactions")
      .then((result) => {
        setAllData(result);
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  const handleConfirm = () => {
    const urlUpload = `${routes.routes.back_end.admin.transactions.upload_currency.url}`;
    const methodUpload = `${routes.routes.back_end.admin.transactions.upload_currency.method}`;

    const formData = new FormData();
    formData.append("file_currency", file);

    fetch(config.getBackEndUrl("transactions") + urlUpload, {
      method: methodUpload,
      body: formData,
    })
      .then((response) => {
        console.log("resp", response);
        if (response.status === 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Successfully uploaded.",
            showConfirmButton: false,
            timer: 1500,
          });
          return response.json();
        } else {
          Swal.fire(
            "Error",
            "An error occurred while uploading the file.",
            "error"
          );
        }
      })
      .then((result) => {
        console.log("result  : ", result);
        if (result?.message == "Sheet name must be named 'Feuil1'") {
          Swal.fire("Error", `Sheet name must be named 'Feuil1'`, "error");
          setAllData("");
        } else {
          let data = [];
          for (const elem in Object?.values(result)) {
            data.push(result[elem]);
          }
          setAllData(data);
          fetchCurrencies();
        }
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        Swal.fire(
          "Error",
          "An error occurred while uploading the file.",
          "error"
        );
        setAllData("");
      });
  };

  console.log("allDataCurrency", allData);
  return (
    <div className="flex flex-fd-c currency">
      <div className="flex flex-fd-c currency_content">
        <div className="currency_content_title">Currency</div>
        <div className="flex currency_content_button">
          <label for="file">
            <div className="flex currency_content_button_upload">
              <img
                className="currency_content_button_upload_img"
                src={gallery}
                alt="select_file"
              />
              <span className="currency_content_button_upload_text">
                Upload document
              </span>

              <input
                id="file"
                type="file"
                onChange={handleChange}
                onClick={(e) => (e.target.value = null)}
                className="avatar_input"
              />
            </div>
          </label>
          {file && (
            <>
              <div className="flex currency_content_button_name-doc">
                <div className="flex currency_content_button_name-doc_content">
                  <img
                    className="currency_content_button_name-doc_content_img"
                    src={icon_doc_name}
                    alt="icon_doc_name"
                  />
                  <span className="currency_content_button_name-doc_content_text">
                    {file.name}
                  </span>
                </div>
                <img
                  className="currency_content_button_name-doc_delete"
                  src={delete_file}
                  alt="delete_file"
                  onClick={() => handleDelete()}
                />
              </div>
              <div className="currency_content_button_confirm">
                <button
                  className="currency_content_button_confirm_btn"
                  onClick={() => handleConfirm()}
                >
                  Confirm
                </button>
              </div>
            </>
          )}
        </div>

        {allData ? (
          <>
            <div className="flex flex-fd-c currency_content_preview">
              <div className=" currency_content_preview_header">
                <div className="flex  currency_content_preview_header_row">
                  <div className=" currency_content_preview_header_row_buffer">
                    Buy add buffer
                  </div>
                  <div className=" currency_content_preview_header_row_buffer">
                    Buy threshold
                  </div>
                  <div className=" currency_content_preview_header_row_buffer">
                    Buy min buffer
                  </div>
                  <div className=" currency_content_preview_header_row_buffer">
                    Code
                  </div>
                  <div className=" currency_content_preview_header_row_buffer">
                    Decimal place
                  </div>
                  <div className=" currency_content_preview_header_row_name">
                    Name
                  </div>
                  <div className=" currency_content_preview_header_row_buffer">
                    Primary market
                  </div>
                  <div className=" currency_content_preview_header_row_buffer">
                    Secondary market
                  </div>
                  <div className=" currency_content_preview_header_row_buffer">
                    Sell buffer
                  </div>
                  <div className=" currency_content_preview_header_row_buffer">
                    Sell threshold
                  </div>
                  <div className=" currency_content_preview_header_row_buffer">
                    Sell min buffer
                  </div>
                </div>
              </div>
              {allData.map((value) => {
                return (
                  <>
                    <div
                      className="flex flex-fd-c currency_content_preview_list"
                      key={value?.id}
                    >
                      <div className="flex  currency_content_preview_list_row">
                        <div className="users_list_row_text currency_content_preview_list_row_buffer">
                          {value?.buy_additional_buffer}
                        </div>
                        <div className="users_list_row_text currency_content_preview_list_row_buffer">
                          {value?.buy_additional_buffer_threshold}
                        </div>
                        <div className="currency_content_preview_list_row_buffer users_list_row_text">
                          {value?.buy_min_buffer}
                        </div>
                        <div className="currency_content_preview_list_row_buffer users_list_row_text">
                          {value?.code}
                        </div>
                        <div className="currency_content_preview_list_row_buffer users_list_row_text">
                          {value?.decimal_place}
                        </div>
                        <div className="currency_content_preview_list_row_name users_list_row_text">
                          {value?.name}
                        </div>
                        <div className="currency_content_preview_list_row_buffer users_list_row_text">
                          {value?.primary_market ? "true" : "false"}
                        </div>
                        <div className="currency_content_preview_list_row_buffer users_list_row_text">
                          {value?.secondary_market ? "true" : "false"}
                        </div>

                        <div className="currency_content_preview_list_row_buffer users_list_row_text">
                          {value?.sell_additional_buffer}
                        </div>
                        <div className="currency_content_preview_list_row_buffer users_list_row_text">
                          {value?.sell_additional_buffer_threshold}
                        </div>
                        <div className="currency_content_preview_list_row_buffer users_list_row_text">
                          {value?.sell_min_buffer}
                        </div>
                      </div>
                    </div>
                    <div className="users_list_line"></div>
                  </>
                );
              })}
            </div>
          </>
        ) : (
          <div className="flex flex-fd-c currency_content_preview currency_content_default-preview">
            <div className="currency_content_preview_text">Preview</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Currency;
