import React, { useState } from "react";
import ApiService from "../../../../Utils/ApiService";
import routes from "../../../../Routes/routes.json";
import { useSelector } from "react-redux";
import "./NewFAQ.scss";
import Swal from "sweetalert2";

const NewFAQ = ({ setIsNewFaq, addNewFaq }) => {
  const [question, setQuestion] = useState("");
  const { user } = useSelector((store) => store.Auth);

  const createFaq = async () => {
    let data = {
      question,
      owner: user.id,
    };
    const route = `${routes.routes.back_end.admin.users.faq.url}`;
    const method = `${routes.routes.back_end.admin.users.faq.method}`;

    const result = await ApiService.sendingPutData(route, method, data, "user");
    addNewFaq(result);
    setIsNewFaq(false);
    Swal.fire({
      icon: "success",
      title: "New faq has been created",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
    });
  };

  return (
    <div className="NewFAQ">
      <div className="form">
        <div className="container-input">
          <span className="label">Topic</span>
          <textarea
            className="input"
            onChange={(e) => setQuestion(e.target.value)}
          ></textarea>
        </div>
      </div>

      <button className="button" onClick={() => question && createFaq()}>
        Save Topic
      </button>
      <button className="button" onClick={() => setIsNewFaq(false)}>
        Cancel
      </button>
    </div>
  );
};

export default NewFAQ;
