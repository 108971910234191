import React, { useState, useEffect, useCallback } from "react";
import "./label.scss";
import { FiEdit3 } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import Swal from "sweetalert2";
import Modal from "../../common/Modal";
import CreateLabel from "./createLabel";
import UpdateLabel from "./editLabel";

const Label = () => {
  const [allLabels, setAllLabels] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [editLabel, setEditLabel] = useState(null);

  const handleClick = useCallback(() => {
    console.log("click here");
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleEdit = (label) => {
    setIsOpenUpdate(true);
    setEditLabel({
      id: label.id,
      name: label.name_label,
      description: label.description_label,
    });
    console.log("valeur à update", label);
  };

  const onCloseUpdate = useCallback(() => {
    setIsOpenUpdate(false);
  }, []);

  const fetchLabels = () => {
    const urlLabels = `${routes.routes.back_end.admin.users.labels.url}`;
    const methodLabels = `${routes.routes.back_end.admin.users.labels.method}`;
    ApiService.fetchApiData(urlLabels, methodLabels, "puzzle")
      .then((result) => {
        setAllLabels(result);
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  useEffect(() => {
    fetchLabels();
  }, []);

  const handleDelete = (labelId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this label!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const urlDelLabels = `${routes.routes.back_end.admin.users.delLabels.url}`;
        const methodDElLabels = `${routes.routes.back_end.admin.users.delLabels.method}`;
        ApiService.fetchApiData(urlDelLabels + labelId, methodDElLabels, "puzzle")
          .then(() => fetchLabels())
          .then(() => {
            Swal.fire("Deleted!", "Your label has been deleted.", "success");
          })
          .catch((error) => {
            console.error("Error deleting label:", error);
            Swal.fire("Error", "An error occurred while deleting the label.", "error");
          });
      }
    });
  };

  const updateLabel = (updatedLabel) => {
    setAllLabels((prevLabels) =>
      prevLabels.map((label) =>
        label.id === updatedLabel.id ? updatedLabel : label
      )
    );
  };

  const updateLabels = (newLabel) => {
    setAllLabels((prevLabels) => [newLabel, ...prevLabels]);
  };

  console.log("LABEL LABEL", allLabels);

  return (
    <>
      <div className="flex flex-js-fs users_select_content_down_line2"></div>
      <div className="flex users_select_content_down_btn">
        <button
          className="users_select_content_down_btn_content padding-label"
          onClick={handleClick}
        >
          Create label
        </button>
        <Modal isOpen={isOpen} title="Create Label" onClose={onClose}>
          <CreateLabel updateLabels={updateLabels} onClose={onClose} />
        </Modal>
        <Modal isOpen={isOpenUpdate} title="Update Label" onClose={onCloseUpdate}>
          <UpdateLabel labelToUpdate={editLabel} updateLabel={updateLabel} onClose={onCloseUpdate} />
        </Modal>
      </div>
      <div className="users_header header-label">
        <div className="flex users_header_row">
          <div className="users_header_row_lastname header-label-name">Name</div>
          <div className="users_header_row_firstname header-label-description">
            Description
          </div>
          <div className="users_header_row_option header-label-option">Option</div>
        </div>
      </div>
      {allLabels.length > 0 ? (
        allLabels.map((label) => (
          <div key={label.id} className="flex users_list_row flex-js-fs">
            <div className="users_list_row_last_name users_list_row_text label-list-name">
              {label.name_label}
            </div>
            <div className="users_list_row_first_name users_list_row_text label-list-description">
              {label.description_label}
            </div>
            <div className="users_list_row_option label-list-option">
              <div className="icon-wrapper">
                <FiEdit3 onClick={() => handleEdit(label)}/>
                <BsTrash onClick={() => handleDelete(label.id)} />
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No labels available.</p>
      )}
    </>
  );
};

export default Label;
