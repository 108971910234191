import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import search from "../../../Assets/vectors/users/search.svg";
import banner1 from "../../../Assets/img/banner/banner1.png";
import littleBanner2 from "../../../Assets/img/banner/littleBanner2.png";
import select from "../../../Assets/vectors/banner/select.svg";
import select_file from "../../../Assets/vectors/banner/select_file.svg";
import { useDispatch } from "react-redux";
import { createbanner } from "../../../Redux/slices/banner";

const NewBanner = ({ dataB, newBanner, currentBannerToUpdate }) => {
  const [valueTabActive, setValueTabActive] = useState("banner");
  const [isNewBanner, setIsNewBanner] = useState(true);
  const [allDataBanner, setAllDataBanner] = useState({ tab: [] });
  const [file, setFile] = useState(null);
  const [bannerImg, setBannerImg] = useState(null);
  const [isHomeSelected, setIsHomeSelected] = useState(false);
  const [isAllPuzzles, setIsAllPuzzles] = useState(false);
  const [isNewPuzzles, setIsNewPuzzles] = useState(false);
  const [isMarketPuzzles, setIsMarketPuzzles] = useState(false);
  const [titleBanner, setTitleBanner] = useState("");
  const [hyperlinkBanner, setHyperlinkBanner] = useState("");

  function handleChange(event) {
    if (event.target.files.length > 0) {
      const file = URL.createObjectURL(event.target.files[0]);
      const bannerFile = event.target.files[0]
      setFile(file);
      setBannerImg(bannerFile)
      console.log("file : ", file);
    }
  }

  useEffect(() => {
    let data = allDataBanner;
    
    data.img = bannerImg;
    data.title = titleBanner;
    data.hyperlink = hyperlinkBanner;
    
    if (isHomeSelected && !data.tab.includes("homepage")) {
      data.tab.push("homepage");
    }
    if (isAllPuzzles && !data.tab.includes("allpuzzles")) {
      data.tab.push("allpuzzles");
    }
    if (isNewPuzzles && !data.tab.includes("Newpuzzles")) {
      data.tab.push("Newpuzzles");
    }
    if (isMarketPuzzles && !data.tab.includes("Puzzlesmarket")) {
      data.tab.push("Puzzlesmarket");
    }
    setAllDataBanner(data);
  }, [
    file,
    titleBanner,
    hyperlinkBanner,
    isHomeSelected,
    isAllPuzzles,
    isNewPuzzles,
    isMarketPuzzles,
  ]);

  useEffect(() => {
    if (currentBannerToUpdate) {
      console.log("currentBannerToUpdate:  ", currentBannerToUpdate);
      if (currentBannerToUpdate.tab.includes("homepage")) {
        setIsHomeSelected(true);
      }
      if (currentBannerToUpdate.tab.includes("allpuzzles")) {
        setIsAllPuzzles(true);
      }
      if (currentBannerToUpdate.tab.includes("Newpuzzles")) {
        setIsNewPuzzles(true);
      }
      if (currentBannerToUpdate.tab.includes("Puzzlesmarket")) {
        setIsMarketPuzzles(true);
      }
      setAllDataBanner(currentBannerToUpdate);
    }
  }, []);

  const dispatch = useDispatch()

  const handleCreateBanner = () => {
    console.log("mandalo etoa")
    dataB(allDataBanner);
    // TODO: dispatch create banner action
    dispatch(createbanner(allDataBanner))
    newBanner();
  };
  const handleSaveBanner = () => {
    currentBannerToUpdate = allDataBanner;
    console.log("allDataBanner in handleSave", allDataBanner);
    newBanner();
  };

  return (
    <div className="flex flex-fd-c banner_new-banner">
      <div className="flex banner_new-banner_first">
        <div className="flex flex-fd-c banner_new-banner_first_title">
          <span className="banner_new-banner_first_title_text">News title</span>
          <input
            className="banner_new-banner_first_title_input banner_new-banner_first_title_first"
            type="text"
            placeholder="what's the title of the banner ?"
            onChange={(e) => setTitleBanner(e.target.value)}
            defaultValue={currentBannerToUpdate && currentBannerToUpdate.title}
          />
        </div>
        <div className="flex flex-fd-c banner_new-banner_first_hyperlink">
          <span className="banner_new-banner_first_title_text">Hyperlink</span>
          <input
            className="banner_new-banner_first_title_input banner_new-banner_first_hyperlink_input"
            type="text"
            placeholder="which page to redirect to ?"
            onChange={(e) => setHyperlinkBanner(e.target.value)}
            defaultValue={
              currentBannerToUpdate && currentBannerToUpdate.hyperlink
            }
          />
        </div>
      </div>
      <div className="flex banner_new-banner_second">
        <div className="flex flex-fd-c banner_new-banner_second_content">
          <div className="banner_new-banner_second_content_text banner_new-banner_first_title_text">
            Display on pages
          </div>
          <div className="flex banner_new-banner_second_content_all-items">
            <div className="flex banner_new-banner_second_content_all-items_home banner_new-banner_second_content_all-items_items"
              onClick={() => setIsHomeSelected(!isHomeSelected)}>
              <div
                className={`users_list_create-user_row_username_admin_select  ${isHomeSelected
                    ? "users_list_create-user_row_username_admin_selected"
                    : ""
                  }`}

              ></div>
              <span className="banner_new-banner_second_content_all-items_home_text">
                HomePage
              </span>
            </div>
            <div className="flex banner_new-banner_second_content_all-items_all banner_new-banner_second_content_all-items_items"
              onClick={() => setIsAllPuzzles(!isAllPuzzles)}>
              <div
                className={`users_list_create-user_row_username_admin_select  ${isAllPuzzles
                    ? "users_list_create-user_row_username_admin_selected"
                    : ""
                  }`}

              ></div>
              <span className="flex banner_new-banner_second_content_all-items_home_text">
                All puzzles
              </span>
            </div>
            <div className="flex banner_new-banner_second_content_all-items_new banner_new-banner_second_content_all-items_items"
              onClick={() => setIsNewPuzzles(!isNewPuzzles)}>
              <div
                className={`users_list_create-user_row_username_admin_select  ${isNewPuzzles
                    ? "users_list_create-user_row_username_admin_selected"
                    : ""
                  }`}

              ></div>
              <span className="banner_new-banner_second_content_all-items_home_text">
                New puzzles
              </span>
            </div>
            <div className="flex banner_new-banner_second_content_all-items_market banner_new-banner_second_content_all-items_items"
              onClick={() => setIsMarketPuzzles(!isMarketPuzzles)}>
              <div
                className={`users_list_create-user_row_username_admin_select  ${isMarketPuzzles
                    ? "users_list_create-user_row_username_admin_selected"
                    : ""
                  }`}

              ></div>
              <span className="banner_new-banner_second_content_all-items_home_text">
                Puzzles market
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex banner_new-banner_third">
        <div className="flex flex-fd-c banner_new-banner_third_content">
          <div className="banner_new-banner_second_content_text banner_new-banner_first_title_text">
            Banner
          </div>
          <label htmlFor="file">
            <div className="flex banner_new-banner_third_content_upload">
              <img src={select_file} alt="select_file" />

              <span className="banner_new-banner_third_content_upload_text">
                Upload image
              </span>

              <input
                id="file"
                type="file"
                onChange={handleChange}
                className="avatar_input"
              />
            </div>
          </label>

          <div className="flex banner_new-banner_third_content_preview">
            {file && (
              <>
                {/* <img
                  className=" banner_new-banner_third_content_preview_full-img"
                  src={file}
                  alt="file"
                /> */}
                <div className="banner_new-banner_third_content_preview_full-img my-backgorund"
              style={{
                backgroundImage: `url(${file})`
              }}></div>
                {/*<div className="banner_new-banner_third_content_preview_title">
                  {titleBanner}
            </div>*/}
              </>
            )}
            {!file && !currentBannerToUpdate && (
              <div className="banner_new-banner_third_content_preview_text">
                Preview
              </div>
            )}
            {!file && currentBannerToUpdate && (
              // <img
              //   className=" banner_new-banner_third_content_preview_full-img"
              //   src={currentBannerToUpdate.img}
              //   alt="file"
              // />
              <div className="banner_new-banner_third_content_preview_full-img my-backgorund"
              style={{
                backgroundImage: `url(${currentBannerToUpdate.img})`
              }}>

              </div>
            )}
          </div>
        </div>
      </div>
      {currentBannerToUpdate ? (
        <div
          className="flex banner_new-banner_btn"
          //onClick={() => dataB(allDataBanner)}
          onClick={() => handleSaveBanner()}
        >
          <button className="banner_new-banner_btn_content">Save</button>
        </div>
      ) : (
        <div
          className="flex banner_new-banner_btn"
          //onClick={() => dataB(allDataBanner)}
          onClick={() => handleCreateBanner()}
        >
          <button className="banner_new-banner_btn_content">
            Create the banner
          </button>
        </div>
      )}

      {console.log("allDataBanner : ", allDataBanner)}
    </div>
  );
};

export default NewBanner;
