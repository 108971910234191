import React, { useEffect, useState } from "react";
import search from "../../../Assets/vectors/users/search.svg";
import { Link, useLocation } from "react-router-dom";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import { useSelector, useDispatch } from "react-redux";

const Transfert = () => {
  const user = useSelector((state) => state.Auth.user);
  const [allHistoric, setAllHistoric] = useState(null);

  useEffect(() => {
    fetchHistoric();
  }, []);

  const fetchHistoric = () => {
    const historicRoute =
      routes.routes.back_end.admin.transactions.transfert.url + user.id;
    const method = `${routes.routes.back_end.admin.transactions.transfert.method}`;

    console.log("historicRoute : ", historicRoute);
    ApiService.fetchApiData(historicRoute, method, "transactions").then(
      (result) => {
        console.log("result historic : ", result);
        setAllHistoric(result);
      }
    );
  };


  return (
    <div className="flex flex-fd-c  transactions">
      <div className="flex users_select flex-fd-c flex-js-fs">
        <div className="flex users_select_content">
          <div className="users_select_item users_select_tab-active">
            <span
              //onClick={() => handleTab("transactions")}
              className="users_select_item_text"
            >
              All transfert
            </span>
          </div>
        </div>
        <div className="flex transactions_select_content_down">
          <div className="flex flex-js-fs transactions_select_content_down_line"></div>
          <div className="flex flex-js-fs transactions_select_content_down_input">
            <input
              className="transactions_select_content_down_input_input"
              placeholder="search in this board"
            />
            <img
              className="transactions_select_content_down_input_img"
              src={search}
              alt="search"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-fd-c">
        <div className="puzzles_header">
          <div className="flex puzzles_header_row">
            <div className="puzzles_header_row_market">user</div>
            <div className="puzzles_header_row_market">Currency</div>
            <div className="puzzles_header_row_market">amount</div>
            <div className="puzzles_header_row_market">In / out</div>
            <div className="puzzles_header_row_market">Reference</div>
            <div className="puzzles_header_row_market">Network</div>
            <div className="puzzles_header_row_market">Adress</div>
            <div className="puzzles_header_row_status">Status </div>
          </div>
        </div>
      </div>
      {allHistoric &&
          allHistoric.map((value) => {
            console.log("value : ", value);
            return (
              <>
                <div className="flex flex-fd-c transactions_list">
                  <div className="flex transactions_list_row flex-js-fs">
                    <div className="users_list_row_text puzzles_header_row_market resize transactions_list_row_first-piece">
                      {user.username}
                    </div>
                    <div className="users_list_row_text puzzles_header_row_market resize">
                      {value.currency}
                    </div>
                    <div className="users_list_row_text puzzles_header_row_market resize">
                      {parseFloat(value.amount).toFixed(2)}
                    </div>
                    <div className="users_list_row_text puzzles_header_row_market resize">
                      {value.action}
                    </div>
                    <div className="users_list_row_text puzzles_header_row_market resize">
                      null
                    </div>
                    <div className="users_list_row_text puzzles_header_row_market resize">
                      null
                    </div>
                    <div className="users_list_row_text puzzles_header_row_market resize">
                      null
                    </div>
                    <div className="users_list_row_text puzzles_header_row_market resize">
                      null
                    </div>
                  </div>
                  <div className="users_list_line"></div>
                </div>
              </>
            );
          })}
    </div>
  );
};

export default Transfert;
