import React, { useCallback, useState } from 'react'
import Modal from '../../Modal';
import BtnSetting from '../../SettingButton';
import AddMember from './AddMember';
import './EditGroup.scss'
import Swal from 'sweetalert2';
import ApiService from "../../../../Utils/ApiService";
import routes from "../../../../Routes/routes.json";

export default function EditGroup({ groupName, users, id, onCloseEdit}) {
  const [addMember, setAddMember] = useState(false);
  const [editName, setEditName] = useState(groupName);
  const [members, setMembers] = useState(users);


  const showAddMember = useCallback(() => {
    setAddMember(true);
  }, []);

  const deleteMembers = (user) => {
    
    const updatedMembers = members.filter(
      (member) => member.user_id !== user.user_id
    );
    setMembers(updatedMembers);
  };

  const handleCancel = () => {
    onCloseEdit();
  };

  const handleEditGroup = () => {
    let data = {};
    data.name_group = editName;
    data.user_group = members?.map((item) => item?.user_id || item?.id);

    const urlCreate = `${routes.routes.back_end.admin.users.editGroup.url}`;
    const methodCreate = `${routes.routes.back_end.admin.users.editGroup.method}`;

    ApiService.sendingPutData(
      urlCreate + "/" + groupName,
      methodCreate,
      data,
      "user"
    )
      .then((status) => {
        console.log("result update Group ", status);
        Swal.fire({
          title: "GROUP UPDATED!",
          text: "SUCCESS",
          icon: "success",
          timer: 1500,
          timerProgressBar: true,
        });
        onCloseEdit();
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire({
          title: "Modification error!",
          text: "ERROR",
          icon: "error",
          timer: 1500,
          timerProgressBar: true,
        });
      });
  };

  const handleDelete = () => {
    let data = {};
    const urlDelete = `${routes.routes.back_end.admin.users.deleteGroup.url}`;
    const methodDelete = `${routes.routes.back_end.admin.users.deleteGroup.method}`;

    data["name"] = groupName;

    ApiService.sendingPutData(
      urlDelete + "/" + groupName,
      methodDelete,
      data,
      "user"
    )
      .then((status) => {
        Swal.fire({
          title: "suppression with success!",
          text: "SUCCESS",
          icon: "success",
          timer: 1500,
          timerProgressBar: true,
        });
        onCloseEdit();
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire({
          title: "Delete error!",
          text: "ERROR",
          icon: "error",
          timer: 1500,
          timerProgressBar: true,
        });
      });
  };

  const addMemberToList = (newMember) => {
    const userExists = members.some(
      (member) =>
        member.user_id === newMember.id || member.id === newMember.id
    );
    if (userExists) {
      Swal.fire({
        title: "User already in the group.!",
        icon: "warning",
        timer: 6000,
        timerProgressBar: true,
        width: 600,
      });
    } else {
      setMembers((prevMembers) => [...prevMembers, newMember]);
    }
};

  const onClose = useCallback(() => {
    setAddMember(false);
  }, []);
  return (
    <div className='EditGroup'>
      <div className='group-name-wrapper'>
        <label htmlFor='group-name'>
          Group name
        </label>
        <input
          className="title-groupItem"
          type="text"
          id="group-name"
          placeholder="Enter the name of group"
          value={editName}
          onChange={(e) => setEditName(e.target.value)}
        />
      </div>
      <div className='group-title'>
        <span>Members</span>
        <span onClick={showAddMember}>+ Add members</span>
        <Modal isOpen={addMember} title="Edit custom group" onClose={onClose}>
          <AddMember onCloseMember={onClose} onAddMember={addMemberToList} />
        </Modal>
      </div>
      <div className="users-list">
        {members.map((user) => (
          <div key={user.id} className="user-item-container">
            <div className="user-item">
              <div className="profile-pic" />
              {user?.user_name ? (
                <label>{user?.user_name}</label>
              ) : (
                <label>{user?.username}</label>
              )}
            </div>
            <div className="trash" onClick={() => deleteMembers(user)} />
          </div>
        ))}
      </div>
      <div className="decision-buttons">
        <BtnSetting text="Cancel" onSubmit={handleCancel} className="cancel" />
        <BtnSetting text="Delete Group" className="cancel" onSubmit={handleDelete} />
        <BtnSetting
          text="Save"
          className="confirm"
          onSubmit={handleEditGroup}
        />
      </div>
    </div>
  )
}
