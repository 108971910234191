import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import notification_icon from '../../Assets/vectors/Navbar/notif.svg';
import NotificationList from './NotificationList';


function Notification() {
  const [isActive, setIsActive] = useState(false);

  const { notifications, isLoading } = useSelector(
    (store) => store.notification
  );

  const [badge, setBadge] = useState(0);

  useEffect(() => {
    if (isLoading === false) {
      if (notifications && notifications.length > 0) {
        const count = notifications.filter(
          (notif) => notif.message_read === false
        );
        setBadge(count.length);
      } else {
        setBadge(0);
      }
    }
  }, [isLoading, notifications]);

  const handleClick = (e) => {
    e.stopPropagation();
    setIsActive(!isActive);
    if (badge > 0) setBadge(0);
  };
  return (
    <div className={`notif ${isActive ? 'active' : ''}`}>
      {badge > 0 && <span className="badge">{badge}</span>}
      <span className="handler" onClick={handleClick} />
      <img
        draggable="false"
        src={notification_icon}
        alt="notify"
        className="notif_icon"
        id="notification_icon"
      />
      {isActive && (
        <div className="content">
          <div className="wrapper">
            <NotificationList />
          </div>
        </div>
      )}
    </div>
  );
}

export default Notification;
