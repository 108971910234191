import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../../Redux/slices/notification";
import ApiService from "../../../Utils/ApiService";

const ListeNotifications = () => {
  const dispatch = useDispatch();
  //GET NOTIF

  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
  }, [data]);

  const fetchData = async () => {
    const res = await ApiService.fetchApiData(
      `/notification/all`,
      "GET",
      "user"
    );
    setData(res);
  };

  return (
    <div class="users_header">
      <div class="flex notif_user_row">
        <div class="notif-user">User</div>
        <div class="notif-description">Description</div>
        <div class="notif-message">Message</div>
        <div class="notif-hyperlink">Hyperlink</div>
      </div>
      <div class="flex flex-fd-c notif_list">
        {data?.map((item, index) => (
          <Fragment>
            <div class="flex user_list_row " key={index}>
              <div class="notif-list-user users_list_row_text">
                {item?.user?.username}
              </div>
              <div class="notif-list-description users_list_row_text">
                {item?.description}
              </div>
              <div class="notif-list-message users_list_row_text">
                {item?.message}
              </div>
              <div class="notif-list-user users_list_row_text">
                {item?.hyperlink}
              </div>
            </div>
            <div class="users_list_line"></div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default ListeNotifications;
