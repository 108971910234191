import React from 'react'

export default function SettingButton({ text, onSubmit }) {
  return (
    <button className='save-button'  onClick={onSubmit
    }>
      {text}
    </button>

  )
}
