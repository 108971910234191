import React from 'react';
import "./input.scss";

function Input({
  label,
  name,
  value,
  onChange = (e) => {},
  placeholder,
  type = 'text',
}) {
  return (
    <div className="controls">
      <label htmlFor={name} className="controls_input_label flex flex-js-fs">
        {label}
      </label>
      {type === 'textarea' ? (
        <textarea
          onChange={onChange}
          name={name}
          id={name}
          cols="30"
          rows="10"
          className="controls_input_textarea"
          placeholder={placeholder}
        />
      ) : (
        <input
          id={name}
          type={type}
          name={name}
          value={value}
          className="controls_input"
          placeholder={placeholder}
          onChange={onChange}
        />
      )}
    </div>
  );
}

export default Input;
