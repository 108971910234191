import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import Banner from "./Components/Admin/Banner/Banner";
import Connect from "./Components/Admin/Connect/Connect";
import Consumption from "./Components/Admin/Consumption/Consumption";
import Currency from "./Components/Admin/Currency/Currency";
import Home from "./Components/Admin/Home";
import Navbar from "./Components/Admin/Navbar/Navbar";
import Notification from "./Components/Admin/Notifications/Notification";
import Piece from "./Components/Admin/Piece/Piece";
import Puzzles from "./Components/Admin/Puzzles/Puzzles";
import Transactions from "./Components/Admin/Transactions/Transactions";
import Transfert from "./Components/Admin/Transfert/Transfert";
import Users from "./Components/Admin/Users/Users";
import FAQ from "./Components/Admin/Faqs";
import { getNotifications } from "./Redux/slices/notification";
import routes from "./Routes/routes.json";
import "./Style/style.scss";
import Settings from "./Components/Admin/Settings";
import NewBanner from "./Components/Admin/Banner/NewBanner";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Preview from "./Components/Admin/Banner/Preview";

function App() {
  const user = useSelector((state) => state.Auth);
  console.log("user : ", user);

  const {
    user: { id },
  } = useSelector((store) => store.Auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotifications(id));
    const interval = setInterval(() => {
      dispatch(getNotifications(id));
    }, 60000);
    return () => clearInterval(interval);
  }, [dispatch, id]);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <div className="app">
        <Router>
          {user.isAuthenticated && user.isAdmin && <Navbar />}
          <Routes>
            {!user.isAuthenticated || !user.isAdmin ? (
              <>
                <Route
                  path={routes.routes.front_end.admin.connection}
                  element={<Connect />}
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </>
            ) : (
              <>
                <Route
                  path={routes.routes.front_end.admin.home}
                  element={<Home />}
                />
                <Route path="*" element={<Navigate to="/users" replace />} />
                <Route
                  path={routes.routes.front_end.admin.users.users}
                  element={<Users />}
                />
                <Route
                  path={routes.routes.front_end.admin.puzzles.puzzles}
                  element={<Puzzles />}
                />
                <Route
                  path={routes.routes.front_end.admin.transactions.transactions}
                  element={<Transactions />}
                />
                <Route
                  path={routes.routes.front_end.admin.banner.main}
                  element={<Banner />}
                />
                <Route
                  path={routes.routes.front_end.admin.banner.create}
                  element={<NewBanner />}
                />

                <Route
                  path={routes.routes.front_end.admin.currency.currency}
                  element={<Currency />}
                />
                <Route
                  path={routes.routes.front_end.admin.transfert.transfert}
                  element={<Transfert />}
                />
                <Route
                  path={
                    routes.routes.front_end.admin.pieces_exchanged
                      .pieces_exchanged
                  }
                  element={<Piece />}
                />
                <Route
                  path={routes.routes.front_end.admin.consumption.consumption}
                  element={<Consumption />}
                />
                <Route
                  path={routes.routes.front_end.admin.faqs.faqs}
                  element={<FAQ />}
                />
                <Route
                  path={routes.routes.front_end.admin.settings.settings}
                  element={<Settings />}
                />
                <Route
                  path={
                    routes.routes.front_end.admin.notifications.notifications
                  }
                  element={<Notification />}
                />
              </>
            )}
          </Routes>
        </Router>

        {/*<Router>
        {!user.isAuthenticated && <Navbar />}
        <div className="app_content">
          <Routes>
            {false ? (
              <>
                <Route
                  path={routes.routes.front_end.admin.user.connection}
                  element={<Connection />}
                />
              </>
            ) : (
              <>
                <Route
                  path={routes.routes.front_end.admin.home}
                  element={<Home />}
                />
                <Route
                  path={routes.routes.front_end.admin.users.users}
                  element={<Users />}
                />
                <Route
                  path={routes.routes.front_end.admin.puzzles.puzzles}
                  element={<Puzzles />}
                />
                <Route
                  path={routes.routes.front_end.admin.transactions.transactions}
                  element={<Transactions />}
                />
                <Route
                  path={routes.routes.front_end.admin.banner.banner}
                  element={<Banner />}
                />
                <Route
                  path={routes.routes.front_end.admin.currency.currency}
                  element={<Currency />}
                />
              </>
            )}
            </Routes>
        </div>
      </Router>*/}
      </div>
    </QueryClientProvider>
  );
}

export default App;
