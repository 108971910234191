import React, { useState, useEffect } from "react";
import "./UserItem.scss";
import { useSelector } from "react-redux";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";

export default function UserItem({ ListUsers, selectAll, onSelectChange }) {
  const links = useSelector((state) => state.User.link);
  
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [select, setSelect] = useState([]);

  const getUsers = () => {
    const urlUsers = `${routes.routes.back_end.admin.users.users.url}`;
    const methodUsers = `${routes.routes.back_end.admin.users.users.method}`;

    ApiService.fetchApiData(urlUsers, methodUsers, "user")
      .then((result) => {
        setAllUsers(result);
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  useEffect(() => {
    getUsers();
  }, []); 

  useEffect(() => {
    const updatedUsers = allUsers.map((user) => ({
      ...user,
      isChecked: false,
    }));
    setUsers(updatedUsers);
  }, [allUsers]);

  const handleUserCheck = (id) => {
    const updatedUsers = users.map((user) =>
      user.id === id ? { ...user, isChecked: !user.isChecked } : user
    );
    setUsers(updatedUsers);
  };

  useEffect(() => {
    if (selectAll) {
      setUsers((prevUsers) =>
        prevUsers.map((user) => ({ ...user, isChecked: true }))
      );
    } else {
      setUsers((prevUsers) =>
        prevUsers.map((user) => ({ ...user, isChecked: false }))
      );
    }
  }, [selectAll]);

  useEffect(() => {
    const selectedUsers = users.filter((user) => user.isChecked);
    setSelect(selectedUsers);
    onSelectChange(selectedUsers);
  }, [users, onSelectChange]);

  return (
    <div className="users-list">
      {users.map((user) => (
        <div className="user-item-container" key={user?.id}>
          <div className="user-item">
            <div className="profile-pic" />
            <label>{user?.username}</label>
          </div>
          <div
            className={`check-user ${user.isChecked ? "checked" : "unchecked"}`}
            onClick={() => handleUserCheck(user.id)}
          />
        </div>
      ))}
    </div>
  );
}
