import React, { useEffect, useMemo, useState } from "react";
// import NewFaq from "./NewFAQ";
import { Link } from "react-router-dom";
import ApiService from "../../../Utils/ApiService";
import Policies from '../Policies'
import routes from "../../../Routes/routes.json";
import FAQ from "../Faqs";
import PersonnalDetails from "./PersonnalDetails";
import Privacy from "./privacy";
import Security from "./security";
import Label from "./label";

import './Settings.scss'

const Settings = () => {

  const [activePage, setActivePage] = useState("privacy")

  const handlePage = page => {
    setActivePage(page);
  };

  return (
    <div className="Settings">
      <div className="page-title">
        <p>Settings</p>
        <div className="register-header">
          <Link to={"/home"}>Home</Link>{" "}
          <div className=".register-gt">{">"}</div>{" "}
          <span>Settings</span>
          <div className=".register-gt">{">"}</div>{" "}
          <span>
            {activePage === "details" && "Personal details"}
            {activePage === "privacy" && "Privacy Settings"}
            {activePage === "security" && "Security"}
            {activePage === "help" && "Help and Feedback"}
            {activePage === "policies" && "Policies"}
            {activePage === "label" && "Label"}
            
          </span>
        </div>
      </div>

      <div className="flex flex-fd-c notif-tab">
        <div className="faq_head flex">
          <span onClick={() => handlePage("details")} className={activePage === "details" ? "active" : ""}>Personal Details</span>
          <span onClick={() => handlePage("privacy")} className={activePage === "privacy" ? "active" : ""}>Privacy Setting</span>
          <span onClick={() => handlePage("security")} className={activePage === "security" ? "active" : ""}>Security</span>
          <span onClick={() => handlePage("help")} className={activePage === "help" ? "active" : ""}>Help and Feedback</span>
          <span onClick={() => handlePage("policies")} className={activePage === "policies" ? "active" : ""}>Policies</span>
          <span onClick={() => handlePage("label")} className={activePage === "label" ? "active" : ""}>Label</span>
        </div>
        {activePage === "details" && <PersonnalDetails />}
        {activePage === "privacy" && <Privacy />}
        {activePage === "security" && <Security />}
        {activePage === "help" && <FAQ />}
        {activePage === "policies" && <Policies/>}
        {activePage === "label" && <Label/>}
        

      </div>
    </div>
  );
};



export default Settings;
