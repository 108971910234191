/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import connection_logo from "../../../Assets/vectors/connection/register_logo.svg";
import password_hidden from "../../../Assets/vectors/connection/password_hidden.svg";
import videoBackground from "../../../Assets/img/puzzle_iconic-v12.mp4";

import useAuth from "../../../hooks/useAuth";
import "./connect.scss";

const Connect = () => {
  const [loggedError, setLoggedError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  const navigate = useNavigate();

  const hidePassword = (id) => {
    let password_input = document.getElementById("password_input");
    switch (id) {
      case "password":
        if (password_input.type === "text") {
          password_input.type = "password";
        } else {
          password_input.type = "text";
        }
        break;
      default:
        break;
    }
  };

  const handleSignIn = () => {
    let data = {};
    data["email"] = email;
    data["password"] = password;

    if (email != "" && password != "") {
      console.log("before fetch api login");
      login({ email, password });
      navigate("/users");
    } else {
      setLoggedError(true);
    }
  };

  return (
    <div className="video-container">
      <video autoPlay muted loop id="background-video">
        <source src={videoBackground} type="video/mp4" />
      </video>
      <div className="content">
        <div className="test">
          <div className="content-form">
            <div className="connection_logo mg-logo flex flex-fd-c">
              <img
                draggable="false"
                src={connection_logo}
                alt="logo"
                className="connection_logo_img"
              />
              <div className="connection_logo_text">Welcome in Iconic !</div>
              {loggedError && (
                <div className="connection_logo_error">
                  L'Email ne corresspond à aucun compte{" "}
                </div>
              )}
            </div>
            <div className="connection_form mg-form flex flex-fd-c">
              <div className="connection_form_text flex flex-js-fs">
                Email or Phone number
              </div>
              <input
                type="email"
                className={`connection_form_input  ${
                  loggedError && "connection_form_error"
                }`}
                placeholder="Enter your e-mail"
                onChange={(e) => {
                  setEmail(e.target.value.trim());
                }}
              ></input>
              <div className="connection_form_text flex flex-js-fs">
                Password
              </div>
              <input
                type="password"
                className={`connection_form_input  ${
                  loggedError && "connection_form_error"
                }`}
                placeholder="Choose your password"
                id="password_input"
                onChange={(e) => {
                  setPassword(e.target.value.trim());
                }}
              ></input>
              <img
                draggable="false"
                src={password_hidden}
                alt=""
                className="connection_form_hide"
                onClick={() => hidePassword("password")}
              />
              {/* <div className="connection_form_footer flex flex-js-fs">
                Forgot password ?
              </div> */}
            </div>
            <div className="connection_footer mg-form flex flex-fd-c">
              <div
                className="connection_footer_btn flex"
                onClick={() => handleSignIn()}
              >
                Sign in
              </div>
              {/* <div className="connection_footer_text">
                Don’t have an account ?{" "}
                <Link to="/register">
                  <span>Create a new one</span>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {console.log("logError : ", loggedError)}
    </div>
  );
};
export default Connect;
