import React, { useState } from "react";
import Input from "../../common/Input";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import Swal from "sweetalert2";

const UpdateLabel = ({ labelToUpdate, onClose, updateLabel }) => {
  const [name, setName] = useState(labelToUpdate.name || "");
  const [description, setDescription] = useState(labelToUpdate.description || "");

  const handleUpdate = () => {
    const updatedLabel = {
      id: labelToUpdate.id,
      name_label: name,
      description_label: description,
    };

    const urlUpdateLabel = `${routes.routes.back_end.admin.users.updateLabel.url}`;
    const methodUpdateLabel = `${routes.routes.back_end.admin.users.updateLabel.method}`;

    ApiService.sendingPutData(
      urlUpdateLabel + "/" + labelToUpdate.id,
      methodUpdateLabel,
      updatedLabel,
      "puzzle"
    )
      .then((result) => {
        console.log("status update", result);
        updateLabel(updatedLabel);
        Swal.fire({
          icon: "success",
          title: "Label has been updated",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        }).then(onClose);
      })
      .catch((error) => {
        console.error("Error updating label:", error);
        Swal.fire({
          icon: "error",
          title: "Label has not been updated",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        }).then(onClose);
      });
  };

  return (
    <div className="NewFAQ">
      <div className="form">
        <div className="container-input">
          <Input
            label="Name"
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            label="Description"
            type="text"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>
      <div className="buttons">
        <button className="button" onClick={handleUpdate}>
          Update
        </button>
      </div>
    </div>
  );
};

export default UpdateLabel;
