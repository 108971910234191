import React from "react";
import "./seeprofil.scss";
import default_avatar from "../../../Assets/vectors/Profile/default_avatar.svg";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import Swal from "sweetalert2";

const SeeProfil = ({ userProfil, onClose }) => {
  const {
    id,
    photo_prise,
    photo_upload,
    first_name,
    last_name,
    birth_date,
    status_verified,
  } = userProfil;

  const fetchApprove = () => {
    const urlApprove = `${routes.routes.back_end.admin.users.approve.url}`;
    const methodApprove = `${routes.routes.back_end.admin.users.approve.method}`;
    ApiService.fetchApiData(urlApprove + id, methodApprove, "user")
      .then((result) => {
        console.log("RESULT", result);
        Swal.fire({
          title: "Profil Approved !",
          text: "SUCCESS",
          icon: "success",
          timer: 1500,
          timerProgressBar: true,
        }).then(() => {
          window.location.reload();
        });
      })

      .catch(() => {
        console.log("error : ");
      });
  };

  const fetchNotApprove = () => {
    const urlNotApprove = `${routes.routes.back_end.admin.users.not_approve.url}`;
    const methodNotApprove = `${routes.routes.back_end.admin.users.not_approve.method}`;
    ApiService.fetchApiData(urlNotApprove + id, methodNotApprove, "user")
      .then((result) => {
        console.log("RESULT", result);
        Swal.fire({
          title: "Profil Not Approved !",
          text: "NOT APPROVE",
          icon: "error",
          timer: 1500,
          timerProgressBar: true,
        }).then(() => {
          window.location.reload();
        });
      })

      .catch(() => {
        console.log("error : ");
      });
  };

  return (
    <>
      <div className="NewFAQ">
        <div className="profile_container_about_left_avatar identity">
          <img
            draggable="false"
            className="profile_container_about_left_avatar_img "
            src={photo_prise ? photo_prise : default_avatar}
            alt=" Photo 1"
          />
          <img
            draggable="false"
            className="profile_container_about_left_avatar_img "
            src={photo_upload ? photo_upload : default_avatar}
            alt=" Photo 2"
          />
        </div>

        <div className="title-profil">
          <h3>
            Firstname : <span className="list-profil">{first_name}</span>{" "}
          </h3>
          <h3>
            Lastname : <span className="list-profil">{last_name}</span>{" "}
          </h3>
          <h3>
            Birthdate : <span className="list-profil">{birth_date}</span>{" "}
          </h3>
        </div>
        {status_verified !== "verified" && (
          <div className="buttons">
            <button className="button" onClick={fetchApprove}>
              APPROVE
            </button>
            <button className="button" onClick={fetchNotApprove}>NOT APPROVE</button>
          </div>
        )}
      </div>
    </>
  );
};

export default SeeProfil;
