import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../../Config/config";
import "./Policies.scss";
import CircleProgressBar from "../../common/CircleProgressBar";
import confirm_check from "../../../Assets/img/Account/confirm_check.svg";

export default function Policies() {

  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const percentage = 100;
  const [progressBar, setProgressBar] = useState(0);

  const handleSuccess = () => {
    setConfirmSuccess(!confirmSuccess);
  };

  const updatePercentage = () => {
    setTimeout(() => {
      setProgressBar(progressBar + 1);
    }, 5);
  };

  useEffect(() => {
    if (percentage > 0) updatePercentage();
  }, [percentage]);
  
  const uploadHtmlFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(`${config.getBackEndUrl("user")}/upload`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log("Upload successful:", data);
        setConfirmSuccess(true); 
      })
      .catch((error) => {
        console.error("Upload error:", error);
      });
  };

  const handleFileUploadAbout = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadHtmlFile(file);
    } else {
      console.log("Aucun fichier sélectionné");
    }
  };
  const handleFileUploadTerm = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadHtmlFile(file);
    } else {
      console.log("Aucun fichier sélectionné");
    }
  };
  const handleFileUploadPolicy = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadHtmlFile(file);
    } else {
      console.log("Aucun fichier sélectionné");
    }
  };

  console.log("URL UPLOAD", `${config.getBackEndUrl("user")}/upload`);

  const uploadTermOfUse = (file) => {
    fetch(`${config.getBackEndUrl("user")}/upload`, {
      method: "POST",
      headers: {
        "Content-Type": "You will perhaps need to define a content-type ",
      },
      body: file,
    })
      .then((response) => response.json())
      .then((success) => console.log(success))
      .catch((error) => console.log(error));
  };

  

  return (
    <div className="Policies">
      <div className="upload">
        <h1>About</h1>
        <label>
          <input
            type="file"
            name="about"
            id="first-upload"
            onChange={handleFileUploadAbout}
          />
          Upload About File
        </label>
      </div>
      <div className="upload">
        <h1>Term of Use</h1>
        <label>
          <input
            type="file"
            name="about"
            id="second-upload"
            onChange={handleFileUploadTerm}
          />
          Upload Term of Use File
        </label>
      </div>
      <div className="upload">
        <h1>Policy</h1>
        <label>
        <input
            type="file"
            name="about"
            id="first-upload"
            onChange={handleFileUploadPolicy}
          />
          Upload Policy File
        </label>
      </div>
      {confirmSuccess && (
        <div className="policiContent">
          <div className="exchange_popup flex flex-fd-c flex-js-fs">
          <CircleProgressBar
              trailStrokeColor="#FD55BA"
              strokeColor="#9B8EFF"
              percentage={progressBar}
              innerText="complete"
              radioCircle1={10}
              radioCircle2={12}
              y={19}
              size={38}
              x={19}
              className=""
              withtext={false}
              strokeDasharray={`${progressBar} ${100 - progressBar}`}
              strokeDashoffset={25}
            />
            <img
              src={confirm_check}
              alt=""
              className="transferout_popup_success"
            ></img>
            <div className="transferout_popup_confirm_text flex flex-ai-c policiContent">
              {" "}
              Upload Succes
            </div>
          </div>
          <div id="overlay" onClick={() => handleSuccess()}></div>
        </div>
      )}
    </div>
    
  );
}
