/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import dot from "../../../Assets/vectors/users/dot.svg";
import search from "../../../Assets/vectors/users/search.svg";
import pp_p from "../../../Assets/vectors/puzzles/pp_p.svg";
import watch from "../../../Assets/vectors/puzzles/watch.svg";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import ReactPaginate from "react-paginate";
import cancel from "../../../Assets/vectors/users/cancel.svg";
import Approve from "./Approve";
import Confirmation from "./CreatePuzzle/PuzzleConfirmation";
import Media from "./CreatePuzzle/PuzzleMedia";
import ProductInformation from "./CreatePuzzle/PuzzleProductInformation";
import Specifications from "./CreatePuzzle/PuzzleSpecification";
import Swal from "sweetalert2";

const Puzzles = () => {
  const [allPuzzles, setAllPuzzles] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [valueTabActive, setValueTabActive] = useState("all puzzles");
  const [newpuzzles, setNewpuzzles] = useState(null);
  const [puzzlesMarket, setPuzzlesMarket] = useState(null);
  const [puzzlesCompleted, setPuzzlesCompleted] = useState(null);
  const [completedPuzzle, setCompletedPuzzzle] = useState(null);
  const [puzzlesCanceled, setPuzzlesCanceled] = useState(null);
  const [approve, setApprove] = useState(null);
  const [currentDataToDisplay, setCurrentDataToDisplay] = useState(null);
  const [, setAllClasses] = useState(null);
  const [, setAllCurrentClass] = useState({});
  const [isPuzzleToUpdate, setIsPuzzleToUpdate] = useState(true);
  const [currentPuzzleToUpdate, setCurrentPuzzleToUpdate] = useState(null);
  const [, setIsBtn] = useState(false);
  const [pageValue, setPageValue] = useState(0);
  //CREATE PUZZLES
  const [isNewPuzzles, setIsNewPuzzles] = useState(false);
  const [isMedia, setIsMedia] = useState(false);
  const [isSpecification, setIsSpecification] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [alldataStep1, setAlldataStep1] = useState(null);
  const [alldataSpecs, setAlldataSpecs] = useState(null);
  const [allMedia, setAllMedia] = useState(null);
  const [fileSpecification, setFileSpecification] = useState(null);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;

  //CREATE PUZZLES
  const handleCreatePuzzle = () => {
    setIsNewPuzzles(true);
  };
  const quitCreation = () => {
    setIsNewPuzzles(false);
    setIsSpecification(false);
    setIsMedia(false);
    setIsConfirmation(false);
  };
  const gotoStep2 = () => {
    setIsNewPuzzles(false);
    setIsSpecification(false);
    setIsConfirmation(false);
    setIsMedia(true);
  };
  const gotoStep3 = () => {
    setIsNewPuzzles(false);
    setIsMedia(false);
    setIsConfirmation(false);
    setIsSpecification(true);
  };
  const gotoConfirm = () => {
    setIsNewPuzzles(false);
    setIsMedia(false);
    setIsSpecification(false);
    setIsConfirmation(true);
  };

  const fetchDataStep1 = (data) => {
    setAlldataStep1(data);
  };
  const fetchAllMedia = (data) => {
    setAllMedia(data);
  };
  const fetchDataSpecs = (data) => {
    setAlldataSpecs(data);
  };
  const fetchFileSpecification = (file) => {
    setFileSpecification(file);
  };
  ////////////////////////

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    fetchPuzzles();
    fetchCanceledPuzzles();
    fetchCompletedPuzzles();
    fetchAllClasses();
  }, []);

  useEffect(() => {
    if (currentDataToDisplay) {
      setPageValue(Math.ceil(currentDataToDisplay.length / usersPerPage));
      //setCurrentDataToDisplay(currentDataToDisplay.slice(0).reverse());
    }
  }, [currentDataToDisplay]);

  useEffect(() => {
    updateCurrentData(allPuzzles);
  }, [valueTabActive]);

  const updateCurrentData = (data) => {
    switch (valueTabActive) {
      case "new puzzles":
        console.log("case new puzzles");
        setCurrentDataToDisplay(
          data.filter(
            (puzzle) =>
              puzzle["market"] === "primary" && puzzle["state"] != "canceled"
          )
        );
        break;
      case "puzzles market":
        console.log("in case puzzles market");
        setCurrentDataToDisplay(
          data.filter(
            (puzzle) =>
              puzzle["market"] === "secondary" && puzzle["state"] != "canceled"
          )
        );
        break;
      case "approve":
        setCurrentDataToDisplay(
          data.filter((puzzle) => puzzle["state"] === "to approve")
        );
        break;

      case "completed":
        console.log("in case puzzles completed");
        // fetchCompletedPuzzles();
        // setCurrentDataToDisplay(
        //   data.filter(
        //     (puzzle) =>
        //       puzzle["market"] === "tertiary" && puzzle["state"] != "canceled"
        //   )
        // );
        break;

      case "canceled":
        console.log("in canceled");
        fetchCanceledPuzzles();
        break;
      default:
        if (allPuzzles) {
          setCurrentDataToDisplay(allPuzzles);
        }
    }
  };

  console.log("complet ve baina", completedPuzzle);

  const fetchPuzzles = () => {
    const urlPuzzles = `${routes.routes.back_end.admin.puzzles.puzzles.url}`;
    const methodPuzzles = `${routes.routes.back_end.admin.puzzles.puzzles.method}`;

    ApiService.fetchApiData(urlPuzzles, methodPuzzles, "puzzle")
      .then((result) => {
        console.log("result all puzlzlessss", result);
        setAllPuzzles(result);
        setCurrentDataToDisplay(result);
        let data = {};
        result.map((value) => {
          data[value.reference] = value.puzzle_class;
        });
        setAllCurrentClass(data);

        setAllPuzzles(result);

        setNewpuzzles(
          result.filter(
            (puzzle) =>
              puzzle["market"] === "primary" && puzzle["state"] !== "canceled"
          )
        );
        setPuzzlesMarket(
          result.filter(
            (puzzle) =>
              puzzle["market"] === "secondary" && puzzle["state"] !== "canceled"
          )
        );
        setCompletedPuzzzle(
          result.filter(
            (puzzle) =>
              puzzle["market"] === "tertiary" && puzzle["state"] !== "canceled"
          )
        );
        setApprove(result.filter((puzzle) => puzzle["state"] === "to approve"));
        // ...
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  const fetchCanceledPuzzles = () => {
    const urlCanceled = `${routes.routes.back_end.admin.puzzles.get_canceled.url}`;
    const methodCanceled = `${routes.routes.back_end.admin.puzzles.get_canceled.method}`;

    ApiService.fetchApiData(urlCanceled, methodCanceled, "puzzle")
      .then((result) => {
        setPuzzlesCanceled(result);
        if (valueTabActive === "canceled") {
          setCurrentDataToDisplay(result);
        }
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  const fetchCompletedPuzzles = () => {
    const urlCompleted = `${routes.routes.back_end.admin.puzzles.get_completed.url}`;
    const methodCompleted = `${routes.routes.back_end.admin.puzzles.get_completed.method}`;
    ApiService.fetchApiData(urlCompleted, methodCompleted, "puzzle")
      .then((result) => {
        console.log("result Completed: ", result);
        setPuzzlesCompleted(result);
        if (valueTabActive === "completed") {
          setCurrentDataToDisplay(result);
        }
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  const fetchAllClasses = () => {
    const urlClass = `${routes.routes.back_end.admin.puzzles.classes.url}`;
    const methodClass = `${routes.routes.back_end.admin.puzzles.classes.method}`;
    ApiService.fetchApiData(urlClass, methodClass, "puzzle")
      .then((result) => {
        console.log("result : ", result);
        setAllClasses(result);
      })
      .catch(() => {
        console.log("error : ");
      });
  };
  const allCompletedPuzzles = completedPuzzle?.concat(puzzlesCompleted);
  console.log("complet rehetra" , allCompletedPuzzles);

  const handleTab = (tab) => {
    if (tab === "all puzzles") {
      setCurrentDataToDisplay(allPuzzles);
    } else {
      // For other tabs, apply the same logic as before
      switch (tab) {
        case "approve":
          setCurrentDataToDisplay(approve);
          break;
        case "new puzzles":
          setCurrentDataToDisplay(newpuzzles);
          break;
        case "puzzles market":
          setCurrentDataToDisplay(puzzlesMarket);
          break;
        case "completed":
          setCurrentDataToDisplay(allCompletedPuzzles);
          break;
        case "canceled":
          setCurrentDataToDisplay(puzzlesCanceled);
          break;
        default:
          break;
      }
    }

    setValueTabActive(tab);
  };

  const fecthUpdatePuzzle = (value, method, data, files, dataMiles) => {
    const urlPuzzleAction = `${routes.routes.back_end.admin.puzzles.puzzle_action.url}`;

    ApiService.fetchApiExpectFormData(
      urlPuzzleAction + value.reference,
      method,
      data,
      files,
      "puzzle"
    )
      .then((result) => {
        fetchPuzzles();
        if(result.code == 200) {
          createMiles(dataMiles);
          Swal.fire(
            "Success",
            "Puzzle updated successfully",
            "success"
          );
        } else {
          Swal.fire(
            "Error",
            result.message,
            "error"
          );
        }
        
      })
      .catch(() => {
        Swal.fire(
          "Error",
          "Can not update this puzzle, data can not be empty ",
          "error"
        );
      });
  };

  const createMiles = (dataMiles) => {
    const urlCreateMiles = `${routes.routes.back_end.admin.puzzles.create_miles.url}`;
    const methodCreateMiles = `${routes.routes.back_end.admin.puzzles.create_miles.method}`;

    ApiService.sendingPutData(
      urlCreateMiles,
      methodCreateMiles,
      dataMiles,
      "puzzle"
    )
      .then((result) => {
        console.log("result create miles ", result);
      })
      .catch(() => {
        console.log("error");
      });
  };

  const handleCanceledPuzzle = (value, method) => {
    setIsPuzzleToUpdate(false);
    let data = {};
    data.state = "canceled";
    //puzzleActions(value, method, data);
    fecthUpdatePuzzle(value, method, data, [], {});
  };

  const handleRestore = (value) => {
    setIsPuzzleToUpdate(false);
    setIsBtn(true);
    let data = {};
    data.state = "validated";
    //puzzleActions(value, "PUT", data);
    fecthUpdatePuzzle(value, "PUT", data, [], {});
  };

  const handleOpenPopup = (puzzle) => {
    console.log("enter open popup");
    //if (valueTabActive == "approve") {
    setIsPuzzleToUpdate(true);
    setCurrentPuzzleToUpdate(puzzle);

    //}
  };
  const handleClosePopup = () => {
    setIsPuzzleToUpdate(false);
  };

  const handleApprovingPuzzle = (value, data, files, dataMiles) => {
    //puzzleActions(value, "PUT", data);
    fecthUpdatePuzzle(value, "PUT", data, files, dataMiles);
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }

  function formatTime(date) {
    return [
      padTo2Digits(date.getHours() - 2),
      padTo2Digits(date.getMinutes()),
    ].join(":");
  }

  const displayContentTab = (value) => {
    return (
      <div
        className="users_list_row_text puzzles_list_row_price "
        onClick={() => handleOpenPopup(value)}
      >
        null
      </div>
    );
  };
  console.log("PUZZLESADMIN", currentDataToDisplay);

  return (
    <>
      <div className="flex flex-fd-c puzzles">
        <div className="flex users_select flex-fd-c flex-js-fs">
          <div className="flex users_select_content">
            <div
              className={`flex users_select_item  ${
                valueTabActive === "all puzzles"
                  ? "users_select_tab-active"
                  : "users_select_tab-inactive"
              }`}
            >
              <span
                onClick={() => handleTab("all puzzles")}
                className="users_select_item_text"
              >
                All Puzzles
              </span>
              <img className="users_select_item_img" src={dot} alt="dot" />
              <span className="users_select_item_number">
                {approve?.length +
                  newpuzzles?.length +
                  puzzlesMarket?.length +
                  allCompletedPuzzles?.length +
                  puzzlesCanceled?.length}
              </span>
            </div>
            <div
              className={`flex users_select_item  ${
                valueTabActive === "approve"
                  ? "users_select_tab-active"
                  : "users_select_tab-inactive"
              }`}
            >
              <span
                onClick={() => handleTab("approve")}
                className="users_select_item_text"
              >
                To approve
              </span>
              <img className="users_select_item_img" src={dot} alt="dot" />
              <span className="users_select_item_number">
                {approve && approve.length}
              </span>
            </div>
            <div
              className={`flex users_select_item  ${
                valueTabActive === "new puzzles"
                  ? "users_select_tab-active"
                  : "users_select_tab-inactive"
              }`}
            >
              <span
                className="users_select_item_text"
                onClick={() => handleTab("new puzzles")}
              >
                New puzzles
              </span>
              <img className="users_select_item_img" src={dot} alt="dot" />
              <span className="users_select_item_number">
                {newpuzzles && newpuzzles.length}
              </span>
            </div>
            <div
              className={`flex users_select_item  ${
                valueTabActive === "puzzles market"
                  ? "users_select_tab-active"
                  : "users_select_tab-inactive"
              }`}
            >
              <span
                className="users_select_item_text"
                onClick={() => handleTab("puzzles market")}
              >
                Puzzles market
              </span>
              <img className="users_select_item_img" src={dot} alt="dot" />
              <span className="users_select_item_number">
                {puzzlesMarket && puzzlesMarket.length}
              </span>
            </div>
            <div
              className={`flex users_select_item  ${
                valueTabActive === "completed"
                  ? "users_select_tab-active"
                  : "users_select_tab-inactive"
              }`}
            >
              <span
                className="users_select_item_text"
                onClick={() => handleTab("completed")}
              >
                Completed
              </span>
              <img className="users_select_item_img" src={dot} alt="dot" />
              <span className="users_select_item_number">
                {allCompletedPuzzles && allCompletedPuzzles?.length}
              </span>
            </div>
            <div
              className={`flex users_select_item  ${
                valueTabActive === "canceled"
                  ? "users_select_tab-active"
                  : "users_select_tab-inactive"
              }`}
            >
              <span
                className="users_select_item_text"
                onClick={() => handleTab("canceled")}
              >
                Canceled
              </span>
              <img className="users_select_item_img" src={dot} alt="dot" />
              <span className="users_select_item_number">
                {puzzlesCanceled && puzzlesCanceled.length}
              </span>
            </div>
          </div>
          <div className="flex users_select_content_down">
            <div className="flex flex-js-fs users_select_content_down_line"></div>
            <div className="flex flex-js-fs users_select_content_down_input">
              <input
                className="users_select_content_down_input_input"
                placeholder="search in this board"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <img
                className="users_select_content_down_input_img"
                src={search}
                alt="search"
              />
            </div>
            <div className="flex flex-js-fs users_select_content_down_line2"></div>
            <div
              className="flex users_select_content_down_btn"
              onClick={() => handleCreatePuzzle()}
            >
              <button className="users_select_content_down_btn_content">
                create Puzzles
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-fd-c puzzles_all-list">
          <div className="puzzles_header scrool">
            <div className="flex puzzles_header_row">
              <div className="puzzles_header_row_id">ID</div>
              <div className="puzzles_header_row_puzzles">Puzzles</div>
              <div className="puzzles_header_row_market">Market</div>
              <div className="puzzles_header_row_market">Class</div>
              <div className="puzzles_header_row_market">Pieces</div>
              <div className="puzzles_header_row_market">Pieces required</div>
              <div className="puzzles_header_row_market">Price</div>
              <div className="puzzles_header_row_market">Creator</div>
              <div className="puzzles_header_row_market">Start Date</div>
              <div className="puzzles_header_row_market">Start time</div>
              <div className="puzzles_header_row_market">End Date</div>
              <div className="puzzles_header_row_market">End time</div>
              <div className="puzzles_header_row_market">Puzzle Mode</div>
              <div className="puzzles_header_row_market">Strike Date</div>
              <div className="puzzles_header_row_market">Selling Fee</div>
              <div className="puzzles_header_row_market">
                Final listing price
              </div>
              <div className="puzzles_header_row_market">Listing Fee(COP)</div>
              <div className="puzzles_header_row_market">
                Listing fee payment
              </div>
              <div className="puzzles_header_row_market">Price per piece</div>
              <div className="puzzles_header_row_status">Status</div>
            </div>
          </div>
          {currentDataToDisplay &&
            currentDataToDisplay
              .filter((value) => value.title.includes(searchValue))
              .slice(0)
              .reverse()
              .slice(pagesVisited, pagesVisited + usersPerPage)
              .map((value, key) => {
                const numberData = key + 1;
                return (
                  <>
                    <div className="flex flex-fd-c puzzles_list scrool">
                      <div className="flex puzzles_list_row flex-js-fs">
                        <div
                          className="users_list_row_text puzzles_list_row_id"
                          onClick={() => handleOpenPopup()}
                        >
                          {numberData}
                        </div>

                        <div
                          className="flex puzzles_list_row_puzzles "
                          onClick={() => handleOpenPopup(value)}
                        >
                          {value.images.length > 0 ? (
                            <img
                              className="puzzles_list_row_puzzles_img"
                              src={value.images[0]}
                            />
                          ) : (
                            <img src={watch} />
                          )}
                          <span>{value.title}</span>
                        </div>
                        <div
                          className="users_list_row_text puzzles_list_row_market "
                          onClick={() => handleOpenPopup(value)}
                        >
                          {value.market == "primary" && (
                            <>
                              <span>New puzzles</span>
                            </>
                          )}
                          {value.market == "secondary" && (
                            <>
                              <span>Puzzles market</span>
                            </>
                          )}
                          {value.market == "tertiary" && (
                            <>
                              <span>Completed</span>
                            </>
                          )}
                          {value.market === null && (
                            <>
                              <span>No market</span>
                            </>
                          )}
                        </div>
                        <div
                          className="users_list_row_text puzzles_list_row_class "
                          onClick={() => handleOpenPopup(value)}
                        >
                          <div className="flex flex-fd-c puzzles_list_row_class_">
                            {value.class}
                          </div>
                        </div>
                        <div
                          className="users_list_row_text puzzles_list_row_pieces "
                          onClick={() => handleOpenPopup(value)}
                        >
                          <div className="puzzles_list_row_pieces_content">
                            {value.total_piece}
                          </div>
                        </div>
                        <div
                          className="users_list_row_text puzzles_list_row_pieces "
                          onClick={() => handleOpenPopup(value)}
                        >
                          <div className="puzzles_list_row_pieces_content">
                            {value.piece_required}
                          </div>
                        </div>
                        <div
                          className="users_list_row_text puzzles_list_row_price "
                          onClick={() => handleOpenPopup(value)}
                        >
                          ${parseFloat(value.initial_price).toFixed(3)}
                        </div>
                        <div
                          className="flex users_list_row_text puzzles_list_row_creator "
                          onClick={() => handleOpenPopup(value)}
                        >
                          <img
                            className="users_list_row_text puzzles_list_row_creator_img"
                            src={pp_p}
                            alt="pp_p"
                          />
                          <span>allan</span>
                        </div>
                        <div
                          className="users_list_row_text puzzles_list_row_price "
                          onClick={() => handleOpenPopup(value)}
                        >
                          {value.date_start &&
                            formatDate(new Date(value.date_start))}
                        </div>
                        <div
                          className="users_list_row_text puzzles_list_row_price "
                          onClick={() => handleOpenPopup(value)}
                        >
                          {value.date_start &&
                            formatTime(new Date(value.date_start))}
                        </div>
                        <div
                          className="users_list_row_text puzzles_list_row_price "
                          onClick={() => handleOpenPopup(value)}
                        >
                          {value.date_end &&
                            formatDate(new Date(value.date_end))}
                        </div>
                        <div
                          className="users_list_row_text puzzles_list_row_price "
                          onClick={() => handleOpenPopup(value)}
                        >
                          {value.date_end &&
                            formatTime(new Date(value.date_end))}
                        </div>
                        <div
                          className="users_list_row_text puzzles_list_row_price "
                          onClick={() => handleOpenPopup(value)}
                        >
                          {value.puzzle_mode}
                        </div>
                        <div
                          className="users_list_row_text puzzles_list_row_price "
                          onClick={() => handleOpenPopup(value)}
                        >
                          {value.date_flash &&
                            formatDate(new Date(value.date_flash))}
                        </div>
                        {displayContentTab(value)}
                        {displayContentTab(value)}
                        {displayContentTab(value)}
                        {displayContentTab(value)}
                        {displayContentTab(value)}
                        <div
                          className="users_list_row_text puzzles_list_row_pieces "
                          onClick={() => handleOpenPopup(value)}
                        >
                          <div className="puzzles_list_row_pieces_content">
                            {value.initial_price / value.total_piece}
                          </div>
                        </div>
                        <div className="flex users_list_row_text puzzles_list_row_status ">
                          <span className="users_list_row_text puzzles_list_row_status_text ">
                            {value.state}
                          </span>

                          {valueTabActive != "approve" &&
                            valueTabActive != "canceled" && (
                              <>
                                <img
                                  className="puzzles_list_row_status_icon"
                                  src={cancel}
                                  alt="cancel"
                                  onClick={() =>
                                    handleCanceledPuzzle(value, "PUT")
                                  }
                                />
                              </>
                            )}
                          {valueTabActive === "canceled" && (
                            <>
                              <div
                                className="users_list_row_text puzzles_list_row_status_btn "
                                onClick={() => handleRestore(value)}
                              >
                                <button className="users_list_row_text puzzles_list_row_status_btn_content ">
                                  Restore
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    {isPuzzleToUpdate && currentPuzzleToUpdate && (
                      <>
                        <Approve
                          currentPuzzle={currentPuzzleToUpdate}
                          handleApprovingPuzzle={handleApprovingPuzzle}
                          handleClosePopup={handleClosePopup}
                          handleCanceledPuzzle={handleCanceledPuzzle}
                        />
                        {/*<div id="overlay"></div>*/}
                      </>
                    )}
                    <div className="puzzle_line scrool"></div>
                  </>
                );
              })}
        </div>
        {/*isPuzzleToUpdate && <Approve />*/}
        {/*CREATE PUZZLE*/}
        <div className="mypuzzles flex flex-fd-c flex-js-fs">
          {isNewPuzzles && (
            <ProductInformation
              gotoStep2={gotoStep2}
              quitCreation={quitCreation}
              fetchDataStep1={fetchDataStep1}
            />
          )}
          {isMedia && (
            <Media
              gotoStep3={gotoStep3}
              quitCreation={quitCreation}
              fetchAllMedia={fetchAllMedia}
            />
          )}
          {isSpecification && (
            <Specifications
              quitCreation={quitCreation}
              gotoConfirm={gotoConfirm}
              fetchFileSpecification={fetchFileSpecification}
              fetchDataSpecs={fetchDataSpecs}
            />
          )}
          {alldataStep1 && isConfirmation && (
            <Confirmation
              allDataStep1={alldataStep1}
              allMedia={allMedia}
              fileSpecification={fileSpecification}
              allDataSpecs={alldataSpecs}
            />
          )}
        </div>

        {currentDataToDisplay && (
          <>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageValue}
              //pagecount={Math.ceil(currentDataToDisplay.length / usersPerPage)}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Puzzles;
