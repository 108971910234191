import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ApiService from "../../../../Utils/ApiService";
import routes from "../../../../Routes/routes.json";
import './AnswerText.scss'

const answerFaq = async ({ user, faq, content }) => {
	const routeReplyFaq = `${routes.routes.back_end.admin.users.answer_faq.url}`;
    const methodReplyFaq = `${routes.routes.back_end.admin.users.answer_faq.method}`;
	ApiService.sendingPutData(
		routeReplyFaq,
		methodReplyFaq,
		{user, faq, content},
		"user"
	  )
		.then((result) => {
		  console.log("reply created", result);
		 
		})
		.catch((err) => {
		  console.log("error : ", err.message);
	});
}
const AnswerText = ({ faq, close }) => {
	const { user } = useSelector((store) => store.Auth)
	const [content, setContent] = useState("")

	return (
		<div className="AnswerText">
			<textarea
				rows={6}
				cols={100}
				className="answer-area"
				onChange={(e) => setContent(e.target.value)}
			>

			</textarea>
			<div>
				<button
					onClick={async (e) => {
						content && await answerFaq({
							faq, content, user: user.id
						})
						close()
					}} >
					Send reply
				</button>
				<button
					onClick={(e) => {
						close()
					}}>
					Close
				</button>
			</div>
		</div>
	)
}

export default AnswerText;
