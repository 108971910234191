import React, { useState, useEffect } from "react";
import Input from "../../common/Input";
import "./createLabel.scss";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import Swal from "sweetalert2";

const CreateLabel = ({ onClose, updateLabels  }) => {
  const [name, setName] = useState("");
  const [labels, setLabels] = useState([])
  const [description, setDescription] = useState("");

  const fetchLabels = () => {
    const urlLabels = `${routes.routes.back_end.admin.users.labels.url}`;
    const methodLabels = `${routes.routes.back_end.admin.users.labels.method}`;
    ApiService.fetchApiData(urlLabels, methodLabels, "puzzle")
      .then((result) => {
        setLabels(result);
      })
      .catch(() => {
        console.log("error : ");
      });
  };
  useEffect(() => {
    fetchLabels();
  }, []);

  const handleCreate = () => {
    const labelData = {
      name_label: name,
      description_label: description,
    };
    sendLabelToBackend(labelData);
    setName("");
    setDescription("");
  };

  const sendLabelToBackend = (labelData) => { 
    const route = `${routes.routes.back_end.admin.users.createLabel.url}`;
    const method = `${routes.routes.back_end.admin.users.createLabel.method}`;
    ApiService.sendingPutData(route, method, labelData, "puzzle") 
      .then((result) => {
        console.log("données envoyées", result);
        const newLabel = { name_label: name, description_label: description , date_create: result.date_create, id: result.id};
        updateLabels(newLabel);
        Swal.fire({
            icon: 'success',
            title: 'Label has been created',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          }).then(
            onClose()
          )
      })
      .catch(() => {
        console.log("erreur :");
      });
  };

  return (
    <div className="NewFAQ">
      <div className="form">
        <div className="container-input">
          <Input
            label="Name"
            placeholder="Name"
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            label="Description"
            placeholder="Description"
            type="text"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>
      <div className="buttons">
        <button className="button" onClick={handleCreate}>
          Create
        </button>
      </div>
    </div>
  );
};

export default CreateLabel;
