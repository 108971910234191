import { createFormData } from ".";
import config from "../Config/config";

class ApiService {
  async fetchApiData(route, method, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    return fetch(fullUrl, {
      method: method,
    })
      .then((response) => response.json())
      .then((res) => {
        return res.data;
      });
  }

  async sendingPutData(route, method, data, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    console.log(data, "data");
    console.log(method, "method");

    return fetch(fullUrl, {
      method: method,
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        return res.data;
      });
  }

  async postExpectFormData(route, method, data, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    const formData = createFormData(data);
    return fetch(fullUrl, {
      method: method,
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      });
  }
  
  async sendingExpectPutData(route, method, data, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    return fetch(fullUrl, {
      method: method,
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      });
  }

  async sendingPutDataLogin(route, method, data, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    console.log(data, "data");
    console.log(method, "method");

    return fetch(fullUrl, {
      method: method,
      body: JSON.stringify(data),
    })
      .then((response) => response.json());
  }

  async postWithoutData(route, method, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    return fetch(fullUrl, {
      method: method,
    })
      .then((response) => response.json())
      .then((res) => {
        return res.data;
      });
  }

  async fetchApiFormData(route, method, data, files, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    for (let value of files) {
      formData.append("file", value);
    }

    return fetch(fullUrl, {
      method: method,
      body: formData,
    }).then((response) =>
      response.json().then((res) => {
        return res.data;
      })
    );
  }

  async fetchApiExpectFormData(route, method, data, files, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    for (let value of files) {
      formData.append("file", value);
    }

    return fetch(fullUrl, {
      method: method,
      body: formData,
    }).then((response) =>
      response.json().then((res) => {
        return res;
      })
    );
  }

  async postFormData(route, method, data, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    const formData = createFormData(data);
    console.log("full url : ", fullUrl);
    return fetch(fullUrl, {
      method: method,
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        return res.data;
      });
  }

  async compare_az(a, b) {
    if (a.username.toLowerCase() < b.username.toLowerCase()) {
      return -1;
    }
    if (a.username.toLowerCase() > b.username.toLowerCase()) {
      return 1;
    }
    return 0;
  }
}

export default new ApiService();
