import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import search from "../../../Assets/vectors/users/search.svg";
import banner1 from "../../../Assets/img/banner/banner1.png";
import banner2 from "../../../Assets/img/banner/banner2.png";
import banner3 from "../../../Assets/img/banner/banner3.png";
import littleBanner2 from "../../../Assets/img/banner/littleBanner2.png";
import NewBanner from "./NewBanner";

import Preview from "./Preview";
import { useDispatch, useSelector } from "react-redux";
import { getallbanners } from "../../../Redux/slices/banner";
import { Fragment } from "react";

const Banner = () => {
  const [valueTabActive, setValueTabActive] = useState("banner");
  const [isPreview, setIsPreview] = useState(false);
  const [isNewBanner, setIsNewBanner] = useState(false);
  const [currentDataBanner, setCurrentDataBanner] = useState(null);
  const [currentBannerToUpdate, setCurrentBannerToUpdate] = useState(null);
  const [allBanner, setAllBanner] = useState([
    {
      img: banner3,
      title: "title 3",
      hyperlink: "link3",
      tab: ["homepage"],
    },
    {
      img: banner2,
      title: "title 2",
      hyperlink: "link1",
      tab: ["homepage", "Puzzlesmarket", "Newpuzzles"],
    },
    {
      img: banner1,
      title: "title 1",
      hyperlink: "link1",
      tab: ["homepage", "allpuzzles", "Puzzlesmarket"],
    },
  ]);

  {/*const updateBanner = (index, newImg) => {
    setAllBanner(allBanner.map((banner, i) => {
      if (i === index) {
        return { ...banner, img: newImg };
      } else {
        return banner;
      }
    }));
  };*/}
  

  const { banners, isLoading } = useSelector(store => store.banner)

  const dispatch = useDispatch()
  const dataBanner = (data) => {
    setCurrentDataBanner(data);
  };
  const updateIsNewBanner = () => {
    setIsNewBanner(false);
  };

  useEffect(() => {
    let data = allBanner;
    if (currentDataBanner) {
      data.push(currentDataBanner);
    }
    setAllBanner([...data]);
  }, [currentDataBanner]);

  useEffect(() => {
    dispatch(getallbanners())
  }, [dispatch]);

  const handleBannerSelected = (value,index) => {
    console.log("value in handleBANNER / ", value);
    //updateBanner(index, value.img)
    setIsNewBanner(true);
    setCurrentBannerToUpdate(value);
  };
  return (
    <div className="flex flex-fd-c banner">
      {!isNewBanner && (
        <div className="flex users_select flex-fd-c flex-js-fs">
          <div className="flex users_select_content">
            <div
              className={`flex users_select_item  ${!isPreview
                ? "users_select_tab-active"
                : "users_select_tab-inactive"
                }`}
            >
              <span
                onClick={() => setIsPreview(false)}
                className="users_select_item_text"
              >
                Banner
              </span>
            </div>
            <div
              className={`flex users_select_item  ${isPreview
                ? "users_select_tab-active"
                : "users_select_tab-inactive"
                }`}
            >
              <span
                className="users_select_item_text"
                onClick={() => setIsPreview(true)}
              >
                Preview
              </span>
            </div>
          </div>
          <div className="flex banner_select_content_down">
            <div className="flex flex-js-fs banner_select_content_down_line"></div>

            <div
              className="flex banner_select_content_down_btn"
              onClick={() => setIsNewBanner(true)}
            >
              <button className="users_select_content_down_btn_content">
                Create Banner
              </button>
            </div>
          </div>
          {!isPreview &&
            banners
              .slice(0)
              .reverse()
              .map((value, index) => {
                console.log("value value : ", value);
                return (
                  <Fragment key={index}>
                    <div
                      className="banner_all-img"
                      //onClick={() => handleBannerSelected(value,index)}
                    >
                      {/* <img
                      className="banner_all-img_item"
                      src={value.img}
                      alt={value.img}
                    /> */}
                      <div className="banner_all-img_item my-background" style={{
                        backgroundImage: `url(${value.img})`
                      }} />
                    </div>
                  </Fragment>
                );
              })}
          {isPreview && (
            <Preview
              allData={banners}
              bannerSelected={handleBannerSelected}
            />
          )}
        </div>
      )}

      {isNewBanner && (

        <Fragment>
          <div className="back-arrow ba-inactive"
            onClick={() => setIsNewBanner(false)} />
          <NewBanner
            dataB={dataBanner}
            newBanner={updateIsNewBanner}
            currentBannerToUpdate={currentBannerToUpdate}
          />
        </Fragment>
      )}
      {console.log("currentBannerToUpdate in banner : ", currentBannerToUpdate)}
    </div>
  );
};

export default Banner;
