import React from 'react'
import BtnSetting from '../../BtnSetting'
import './Unblock.scss'

export default function Unblock() {
  return (
    <div className='Unblock'>
      <div className='unblock-label'>Do you want to unblock eddy97 ?</div>
      <div className='decision-buttons'>
        <BtnSetting text='Cancel' className='cancel' /> 
        <BtnSetting text='Unblock' className='confirm' /> 
      </div>
    </div>
  )
}
