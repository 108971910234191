import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import avatar from "../../../Assets/vectors/users/avatar.svg";
import dot from "../../../Assets/vectors/users/dot.svg";
import edit from "../../../Assets/vectors/users/edit.svg";
import reset from "../../../Assets/vectors/users/reset.svg";
import del from "../../../Assets/vectors/users/del.svg";
import profile from "../../../Assets/vectors/users/profile.svg";
import pp from "../../../Assets/img/users/pp.png";
import search from "../../../Assets/vectors/users/search.svg";
import avatar_user from "../../../Assets/vectors/users/avatar_user.svg";
import cancel from "../../../Assets/vectors/users/cancel.svg";
import validate from "../../../Assets/vectors/users/validate.svg";
import admin_icon from "../../../Assets/vectors/users/admin_icon.svg";
import icon_adm from "../../../Assets/vectors/users/icon_adm.svg";
import eye from "../../../Assets/vectors/users/eye.svg";
import sort_white from "../../../Assets/vectors/users/sort_white.svg";
import az_icon from "../../../Assets/vectors/users/a-z.svg";
import za_icon from "../../../Assets/vectors/users/z-a.svg";
import routes from "../../../Routes/routes.json";
import ApiService from "../../../Utils/ApiService";
import PopupUpdate from "../Users/PopupUpdate";
import ReactPaginate from "react-paginate";
import NewNotification from "./NewNotification";
import ListeNotifications from "./ListeNotification";

const Notification = () => {


  const [isNewNotification, setIsNewNotification] = useState(false)
  return (
    <div className="flex flex-fd-c notif-tab">

      {!isNewNotification && (
        <div className="flex users_select flex-fd-c flex-js-fs">
          <div className="flex users_select_content">

          </div>
          <div className="flex users_select_content_down">
            <div className="flex flex-js-fs users_select_content_down_line"></div>
            <div className="flex flex-js-fs users_select_content_down_input">
              {/* <input
                className="users_select_content_down_input_input"
                placeholder="search in this board"
              />
              <img
                className="users_select_content_down_input_img"
                src={search}
                alt="search"
              /> */}
            </div>
            <div className="flex flex-js-fs users_select_content_down_line2"></div>
            <div
              className="flex users_select_content_down_btn"
              onClick={() => setIsNewNotification(true)}
            >
              <button className="users_select_content_down_btn_content">
                create notification
              </button>
            </div>
          </div>
          <ListeNotifications />
        </div>
      )}
      {isNewNotification && (
        <NewNotification setIsNewNotification={setIsNewNotification} />
      )

      }
    </div>
  );
};



export default Notification;
