import React, { useState } from "react";
import Input from "../Input";
import gallery from "../../../Assets/vectors//Mypuzzles/gallery.svg";
import docs from "../../../Assets/vectors/Mypuzzles/docs.svg";
import link from "../../../Assets/vectors/Mypuzzles/link.svg";
import close from "../../../Assets/vectors/Mypuzzles/close.svg";
import routes from "../../../Routes/routes.json";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss"
import ApiService from "../../../Utils/ApiService";
import { getUser, getUserSuccess } from "../../../Redux/slices/user";
import Swal from "sweetalert2";

const VerificationAccount = ({ onClose, setVerificationStatus }) => {
  const [firstName, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [date, setDate] = useState("");
  const [isLinkActive, setIsLinkActive] = useState(false);
  const [file, setFile] = useState(null);
  const [linkSpecsValue, setLinkSpecsValue] = useState("");
  const user = useSelector((state) => state.User);
  const [uploadedPhotoURL, setUploadedPhotoURL] = useState(null);
  const [, setPhotoUploadName] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setFile(file);
    }
  };

  const handleCloseDocs = () => {
    setFile(null);
  };

  const handleVerify = () => {
    const routeVerifyUser = `${routes.routes.back_end.admin.users.verify_user.url}`;
    const method = `${routes.routes.back_end.admin.users.verify_user.method}`;
    const userId = user.id;
    let data = {};
    data.first_name = firstName;
    data.last_name = lastname;
    data.birth_date = date;
    data.photo_prise = photoURL;
    data.photo_upload = uploadedPhotoURL;
    ApiService.postExpectFormData(routeVerifyUser, method, data, "user")
      .then((result) => {
        getUser(result);
        if (result.message === "success") {
          setVerificationStatus("success");
          localStorage.setItem(`verificationStatus_${userId}`, "success");
          Swal.fire({
            title: "Verification in Progress",
            text: "SUCCESS",
            icon: "success",
            timer: 1500,
            timerProgressBar: true,
          }).then(() => {
            window.location.reload();
          });
          dispatch(getUserSuccess({ ...user, status_verified: "pending" }));
        } else {
          setVerificationStatus("failed");
          localStorage.setItem(`verificationStatus_${userId}`, "failed");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            timer: 1500,
            text: "Verification Failed",
            timerProgressBar: true,
          });
        }
      })
      .then(() => {
        onClose();
      })
      .catch((err) => {
        console.log("error", routeVerifyUser);
      });
  };

  return (
    <div className="NewFAQ">
      <div className="form">
        <div className="container-input">
          <Input
            label="FirstName"
            placeholder="First Name"
            type="text"
            name="firstname"
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <Input
            label="LastName"
            placeholder="Last Name"
            type="text"
            name="lastname"
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
          <Input
            label="Birth Date"
            placeholder="Birth date"
            type="date"
            name="birthdate"
            onChange={(e) => {
              setDate(e.target.value);
            }}
          />
        </div>
        <div className="flex-elem flex-fd-c mypuzzles_specification_file-link">
          <div className="flex-elem  mypuzzles_specification_file-link_all-btn">
            <div
              className="flex-elem mypuzzles_specification_file-link_all-btn_file mypuzzles_specification_file-link_all-btn_file_link"
              onClick={() => setIsLinkActive(true)}
            >
              <span className="mypuzzles_specification_file-link_all-btn_file_text">
                Add Your ID Card
              </span>
            </div>
          </div>
        </div>
        {isLinkActive && (
          <div className="mypuzzles_specification_link-input-container">
            <div className="flex-elem mypuzzles_specification_link-input">
              <img
                className="mypuzzles_specification_link-input_icon"
                src={link}
                alt="link"
              />
              <input
                type="text"
                className="mypuzzles_specification_link-input_input"
                onChange={(e) => setLinkSpecsValue(e.target.value)}
              />
            </div>
            <img
              className="mypuzzles_specification_link-input_close"
              src={close}
              alt="close"
              onClick={() => setIsLinkActive(false)}
            />
          </div>
        )}
      </div>

      <div className="buttons">
        <button className="button" onClick={handleVerify} >Verify My identity</button>
      </div>
    </div>
  );
};

export default VerificationAccount;
