import React, { useEffect, useState, useCallback, useMemo } from "react";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import { useSelector, useDispatch } from "react-redux";
import CustomGroup from "../../common/CustomGroup";
import CircleProgressBar from "../../common/CircleProgressBar";
import confirm_check from "../../../Assets/img/Account/confirm_check.svg";
import RadioInput from "../../common/RadioButton";
import CustomGroupButton from "../../common/RadioButton/CustomGroup";
import SettingButton from "../../common/SettingButton";
import Block from "../../common/Block";
import Modal from "../../common/Modal";
import Blocked from "../../common/Block/BlockedItem";
import Groups from "../../common/Group/index";
import GroupItem from "../../common/Group/GroupItem";
import "./privacy.scss";
import { updateUsers } from "../../../Redux/slices/user";

const Privacy = (props) => {
  const userConnected = useSelector((state) => state.Auth.user);
  const links = useSelector((state) => state.User.link);
  const [allPrivacy, setAllPrivacy] = useState(null);
  const [control, setControl] = useState(false);
  const [allDataPrivacy, setAllDataPrivacy] = useState({});
  const [postCustomValue, setPostCustomValue] = useState("");
  const [usersPostSearched, setUsersPostSearched] = useState(null);
  const [dataUsersSelected, setDataUsersSelected] = useState({});
  const [seeActivityCustomGroup, setSeeActivityCustomGroup] = useState([]);
  const [dataUsersBlockSelected, setDataUsersBlockSelected] = useState({});
  const [seeActivityBlockGroup, setSeeActivityBlockGroup] = useState([]);
  const [customPopupActive, setCustomPopupActive] = useState(true);

  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const percentage = 100;
  const [progressBar, setProgressBar] = useState(0);
  const [user, setUser] = useState([]);
  const [groupChange, setGroupChange] = useState();
  const [isGroupModal, setIsGroupModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchPrivacy();
  }, []);

  const [allGroup, setAllGroup] = useState([]);

  useEffect(() => {
    fetchGroup();
  }, [isGroupModal, groupChange]);

  const fetchGroup = () => {
    const urlGroup = `${routes.routes.back_end.admin.users.getGroup.url}`;
    const methodGroup = `${routes.routes.back_end.admin.users.getGroup.method}`;
    ApiService.fetchApiData(urlGroup, methodGroup, "user")
      .then((result) => {
        setAllGroup(result);
      })
      .catch(() => {
        console.log("error : ");
      });
  };
  useEffect(() => {
    processElementsSearched(links.filter((link) => link["status"] === "bind"));
  }, [postCustomValue]);

  const handleSuccess = () => {
    setConfirmSuccess(!confirmSuccess);
  };

  const updatePercentage = () => {
    setTimeout(() => {
      setProgressBar(progressBar + 1);
    }, 5);
  };
  const handleGroupChange = (group) => {
    // Handle the received group data from GroupItem
    console.log("groupChange", group);
    setGroupChange(group);
  };

  useEffect(() => {
    if (percentage > 0) updatePercentage();
  }, [percentage]);

  useEffect(() => {
    if (progressBar < percentage) updatePercentage();
  }, [progressBar]);

  useEffect(()=> {
    fetchUser();
  },[]);
  useEffect(() => {
    if (user && user.privacy) {
      setVisit(user.privacy.visit_my_profile);
      setTag(user.privacy.tag_me);
      setMessage(user.privacy.message_me);
      setmyActivity(user.privacy.see_my_activity);
    }
    
  }, [user]);

  const fetchUser = () => {
    const routeGetUser = `${routes.routes.back_end.admin.users.get_user.url}`;
    const methodGetUser = `${routes.routes.back_end.admin.users.get_user.method}`;

    ApiService.fetchApiData(
      routeGetUser + "/id/" + userConnected.id,
      methodGetUser,
      "user"
    )
      .then((result) => {
        setUser(result);
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });
 }

  const fetchPrivacy = () => {
    const routePrivacy = `${routes.routes.back_end.admin.users.user_privacy.url}`;
    const methodPrivacy = `${routes.routes.back_end.admin.users.user_privacy.get_method}`;

    ApiService.fetchApiData(routePrivacy + userConnected.username, methodPrivacy, "user")
      .then((result) => {
        console.log("result privacy : ", result);
        setAllPrivacy(result);
        setAllDataPrivacy(result.privacy);
        if (result.privacy["see_my_activity"]["custom group"]) {
          let data = {};
          setSeeActivityCustomGroup(
            result.privacy["see_my_activity"]["custom group"]
          );
          result.privacy["see_my_activity"]["custom group"].map((value) => {
            data[value] = true;
          });
          console.log("custom group exist");
          setDataUsersSelected(data);
        } else if (result.privacy["see_my_activity"]["my_links"]) {
          console.log("block group");
          let data = {};
          setSeeActivityBlockGroup(
            result.privacy["see_my_activity"]["my_links"]
          );
          result.privacy["see_my_activity"]["my_links"].map((value) => {
            data[value] = true;
          });
          setDataUsersBlockSelected(data);
        }
      })
      .catch(() => {
        console.log("error get privacy : ");
      });
  };

  /*const addUserCustomGroupActivity = (username) => {
    let data = seeActivityCustomGroup;
    if (!data.includes(username)) {
      data.push(username);
    }
    else{
      // delete username in the liste
    }
    setSeeActivityCustomGroup(data);
  };*/

  const addUserCustomGroupActivity = (username, add, custom) => {
    let data = {};
    if (custom) {
      data = seeActivityCustomGroup;
    } else {
      data = seeActivityBlockGroup;
    }

    if (!data.includes(username) && add) {
      data.push(username);
    } else {
      // delete username in the liste
      let myIndex = data.indexOf(username);
      if (myIndex !== -1) {
        data.splice(myIndex, 1);
      }
    }
    if (custom) {
      setSeeActivityCustomGroup([...data]);
    } else {
      setSeeActivityBlockGroup([...data]);
    }
  };

  const ChangeCustomPopupActive = () => {
    setCustomPopupActive(!customPopupActive);
  };

  const processElementsSearched = (allusers) => {
    let listUsers = [];
    if (postCustomValue === "") {
      setUsersPostSearched(listUsers);
      //setIsSearchActive(false);
    } else {
      for (let index = 0; index < allusers.length; index++) {
        const element = allusers[index];
        if (element.username.includes(postCustomValue)) {
          listUsers.push(element);
        }
      }
      setUsersPostSearched(listUsers);
      //console.log("listUsers searched : ", listUsers);
    }
  };

  const handleValidate = (elem) => {
    //
    const routePrivacy = `${routes.routes.back_end.admin.users.user_privacy.url}`;
    const methodPrivacy = `${routes.routes.back_end.admin.users.user_privacy.put_method}`;

    let data = {};
    //console.log("allDataPrivacy in handleValidate : ", allDataPrivacy);
    if (elem == "validate") {
      data["account_type"] = allDataPrivacy.account_type;
      data["visit_my_profile"] = allDataPrivacy.visit_my_profile;
      //data["see_my_activity"] = allDataPrivacy.see_my_activity;
      data["tag_me"] = allDataPrivacy.tag_me;
      data["message_me"] = allDataPrivacy.message_me;

      if (
        seeActivityCustomGroup.length != 0 &&
        allDataPrivacy.see_my_activity == "custom group"
      ) {
        data["see_my_activity"] = { "custom group": seeActivityCustomGroup };
        //data["see_my_activity"] = { my_links: seeActivityCustomGroup };
      } else if (
        seeActivityBlockGroup.length != 0 &&
        allDataPrivacy.see_my_activity == "my_links"
      ) {
        data["see_my_activity"] = { my_links: seeActivityBlockGroup };
      } else {
        data["see_my_activity"] = allDataPrivacy.see_my_activity;
      }
    } else {
      data["account_type"] = "public";
      data["visit_my_profile"] = "public";
      //data["see_my_activity"] = allDataPrivacy.see_my_activity;
      data["tag_me"] = "my_links";
      data["message_me"] = "my_links";
      data["see_my_activity"] = "my_links";
    }

    ApiService.sendingPutData(
      routePrivacy + user.username,
      methodPrivacy,
      data,
      "user"
    ).then((result) => {
      setConfirmSuccess(true);
      console.log("result after update data : ", result);
      if (elem == "restore") {
        fetchPrivacy();
      }
    });
  };

  const handleActiveItem = (name, key) => {
    //console.log("name and key in handleClick : ", name, "-", key);
    let data = allDataPrivacy;
    data[key] = name;
    //console.log("data : ", data);

    if (
      key == "account_type" ||
      key == "see_my_activity" ||
      key == "message_me" ||
      key == "tag_me" ||
      key == "visit_my_profile"
    ) {
      setControl(!control);
    }
    /*if (key == "see_my_activity") {
      setPostValue(name);
    }*/

    setAllDataPrivacy(data);
  };

  const renderRadioSelect = (key, name) => {
    return (
      <>
        <div
          className={
            allDataPrivacy[key] != name
              ? "privacy_container_content_item_select_selected"
              : "privacy_container_content_item_select_selected privacy_container_content_item_select_active"
          }
        ></div>
      </>
    );
  };

  const renderItemSelect = (name, key) => {
    //console.log("name : ", name, " -- key : ", key);
    return (
      <>
        <div
          className="flex-elem  privacy_container_content_item_select_elem privacy_container_content_item_select_private"
          onClick={() => handleActiveItem(name, key)}
        >
          {key == "account_type" && renderRadioSelect(key, name)}
          {key == "visit_my_profile" && renderRadioSelect(key, name)}
          {key == "see_my_activity" && renderRadioSelect(key, name)}
          {key == "tag_me" && renderRadioSelect(key, name)}
          {key == "message_me" && renderRadioSelect(key, name)}
          <span className="privacy_container_content_item_select_all-text">
            {name}
          </span>
        </div>
      </>
    );
  };
  const [isPublic, setIsPublic] = useState(true);
  const [visit, setVisit] = useState("");
  const [tag, setTag] = useState("");
  const [message, setMessage] = useState("");
  const [myActivity, setmyActivity] = useState("");
  const [activeChoice, setAciveChoice] = useState([]);
  const toggleActive = useMemo(
    () => (key) => {
      setAciveChoice([key]);
    },
    [activeChoice]
  );

  const [isBlockModal, setIsBlockModal] = useState(false);

  const handleBlockModal = useCallback(() => {
    setIsBlockModal(true);
  }, []);

  const onClose = useCallback(() => {
    setIsBlockModal(false);
  }, []);

  const handleModal = useCallback(() => {
    setIsGroupModal(true);
  }, []);

  const onGroupClose = useCallback(() => {
    setIsGroupModal(false);
  }, []);

  const getLabel = useMemo(
    () => (key) => {
      switch (key) {
        case "everybody":
          return "Everybody";
        case "nobody":
          return "Nobody";
        case "public":
          return 'Only my links'
        default:
          return "";
      }
    },
    [visit, tag, message, myActivity]
  );

  const resetChanges = () => {
    setVisit('Only my links');
    setTag('Only my links');
    setMessage('Only my links');
    setmyActivity('Only my links');
  }

  const handleSaveData = (updateField, newValue) => {
    const routeUpdateUser = `${routes.routes.back_end.admin.users.update_user.url}`;
    const methodUpdateUser = `${routes.routes.back_end.admin.users.update_user.method}`;
    let data = {
      privacy: {
        visit_my_profile: visit ?? '',
        tag_me: tag ?? '',
        message_me: message ?? '',
        see_my_activity: myActivity ?? ''
      }
    };
    data.privacy[updateField] = newValue;

    const datatosend = { privacy: { ...data.privacy } };

    ApiService.sendingPutData(
      `${routeUpdateUser}/${user.id}`,
      methodUpdateUser,
      datatosend,
      "user"
    )
      .then((result) => {
        dispatch(updateUsers(result));
        setAciveChoice([]);
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });
  };
  
  const handleSaveVisit = () => {
    handleSaveData("visit_my_profile", visit);
  };
  
  const handleSaveTag = () => {
    handleSaveData("tag_me", tag);
  };
  
  const handleSaveMessage = () => {
    handleSaveData("message_me", message);
  };
  const handleSaveActivity = () => {
    handleSaveData("see_my_activity", myActivity)
  };

  return (
    <div className="privacy" style={{ paddingTop: 25 }}>
      <div className="option-wrapper">
        <div className="items">
          <div className="item-select">Account Type</div>
          <div className="side">
            <div className="item-detail">
              {isPublic ? "Profile set to public" : "Profile set to private"}
            </div>
            <label class="switch">
              <input type="checkbox" onClick={() => setIsPublic(!isPublic)} />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>

      {isPublic && (
        <React.Fragment>
          <div className="option-title">Selection privacy option</div>
          <div className="option-wrapper">
            <div className="items">
              <div className="item-select">Visit on my profile</div>
              <div className="item-value" onClick={() => toggleActive("visit")}>
                <span>{getLabel(visit)}</span>
                {console.log("visit ", visit)}

                <span className="arrow" />
              </div>
            </div>

            {activeChoice.includes("visit") && (
              <div className="choices">
                <div className="choice-title">Who can see my activity</div>
                <div className="choices-list">
                  <div className="choice-content">
                    <RadioInput
                      label="Only my links"
                      value="public"
                      checked={visit}
                      setter={setVisit}
                    />
                    <RadioInput
                      label="Everybody"
                      value="everybody"
                      checked={visit}
                      setter={setVisit}
                    />
                    <RadioInput
                      label="Nobody"
                      value="nobody"
                      checked={visit}
                      setter={setVisit}
                    />
                    <CustomGroupButton />
                  </div>
                  <SettingButton 
                    text="Save changes" 
                    onSubmit={handleSaveVisit}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="option-wrapper">
            <div className="items">
              <div className="item-select">Tags</div>
              <div className="item-value" onClick={() => toggleActive("tags")}>
                <span>{getLabel(tag)}</span>
                <span className="arrow" />
              </div>
            </div>

            {activeChoice.includes("tags") && (
              <div className="choices">
                <div className="choice-title">Who can tag me</div>
                <div className="choices-list">
                  <div className="choice-content">
                    <RadioInput
                      label="Only my links"
                      value="public"
                      checked={tag}
                      setter={setTag}
                    />
                    <RadioInput
                      label="Everybody"
                      value="everybody"
                      checked={tag}
                      setter={setTag}
                    />
                    <RadioInput
                      label="Nobody"
                      value="nobody"
                      checked={tag}
                      setter={setTag}
                    />
                    <CustomGroupButton />
                  </div>
                  <SettingButton 
                    text="Save changes" 
                    onSubmit={handleSaveTag}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="option-wrapper">
            <div className="items">
              <div className="item-select">Send me a message</div>
              <div
                className="item-value"
                onClick={() => toggleActive("message")}
              >
                <span> {getLabel(message)} </span>
                <span className="arrow" />
              </div>
            </div>

            {activeChoice.includes("message") && (
              <div className="choices">
                <div className="choice-title">Who can message me</div>
                <div className="choices-list">
                  <div className="choice-content">
                    <RadioInput
                      label="Only my links"
                      value="public"
                      checked={message}
                      setter={setMessage}
                    />
                    <RadioInput
                      label="Everybody"
                      value="everybody"
                      checked={message}
                      setter={setMessage}
                    />
                    <RadioInput
                      label="Nobody"
                      value="nobody"
                      checked={message}
                      setter={setMessage}
                    />
                    <CustomGroupButton />
                  </div>
                  <SettingButton 
                    text="Save changes"
                    onSubmit={handleSaveMessage}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="option-wrapper">
            <div className="items">
              <div className="item-select">See my Activity</div>
              <div
                className="item-value"
                onClick={() => toggleActive("activity")}
              >
                <span>{getLabel(myActivity)}</span>
                {console.log("Activity ", myActivity)}
                <span className="arrow" />
              </div>
            </div>

            {activeChoice.includes("activity") && (
              <div className="choices">
                <div className="choice-title">Who can see my Activity</div>
                <div className="choices-list">
                  <div className="choice-content">
                    <RadioInput
                      label="Only my links"
                      value="public"
                      checked={myActivity}
                      setter={setmyActivity}
                    />
                    <RadioInput
                      label="Everybody"
                      value="everybody"
                      checked={myActivity}
                      setter={setmyActivity}
                    />
                    <RadioInput
                      label="Nobody"
                      value="nobody"
                      checked={myActivity}
                      setter={setmyActivity}
                    />
                    <CustomGroupButton />
                  </div>
                  <SettingButton 
                    text="Save changes"
                    onSubmit={handleSaveActivity}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="option-title">User privacy option</div>
          <div className="option-wrapper">
            <div className="items">
              <div className="item-select">Custom group</div>
              <div
                className="item-value"
                onClick={() => toggleActive("custom")}
              >
                <span>number of groups ({allGroup?.length})</span>
                <span className="arrow" />
              </div>
            </div>

            {activeChoice.includes("custom") && (
              <div className="choices">
                <div className="choice-title">Group list</div>
                <div className="choices-list">
                  <div className="choice-content">
                    <div className="block-user" onClick={handleModal}>
                      Add group
                    </div>
                    <Modal
                      isOpen={isGroupModal}
                      title="Create custom group"
                      onClose={onGroupClose}
                    >
                      <Groups onClose={onGroupClose} />
                    </Modal>

                    {/* Group */}
                    <div className="blocked-list">
                      <GroupItem onGroupChange={handleGroupChange} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="option-wrapper">
            <div className="items">
              <div className="item-select">Blocked group</div>
              <div className="item-value" onClick={() => toggleActive("block")}>
                <span>1 user blocked</span>
                <span className="arrow" />
              </div>
            </div>

            {activeChoice.includes("block") && (
              <div className="choices">
                <div className="choice-title">Block list</div>
                <div className="choices-list">
                  <div className="choice-content">
                    <div className="block-user" onClick={handleBlockModal}>
                      Add user
                    </div>
                    <Modal
                      isOpen={isBlockModal}
                      title="Select user to block"
                      onClose={onClose}
                    >
                      <Block />
                    </Modal>

                    {/* unblock */}
                    <div className="blocked-list">
                      <Blocked />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      )}

      <div className="reset-container">
        <SettingButton 
          text="Reset all changes"
          onSubmit ={resetChanges}
        />
      </div>

      {/* old one */}

      {/*console.log("seeActivityCustomGroup : ", seeActivityCustomGroup)*/}
      {confirmSuccess && (
        <div className="flex">
          <div className="exchange_popup flex flex-fd-c flex-js-fs">
            <CircleProgressBar
              trailStrokeColor="#FD55BA"
              strokeColor="#9B8EFF"
              percentage={progressBar}
              innerText="complete"
              radioCircle1={10}
              radioCircle2={12}
              y={19}
              size={38}
              x={19}
              className=""
              withtext={false}
              strokeDasharray={`${progressBar} ${100 - progressBar}`}
              strokeDashoffset={25}
            />
            <img
              src={confirm_check}
              alt=""
              className="transferout_popup_success"
            ></img>
            <div className="transferout_popup_confirm_text flex flex-ai-c">
              {" "}
              Information saved
            </div>
          </div>
          <div id="overlay" onClick={() => handleSuccess()}></div>
        </div>
      )}
      {console.log("seeActivityCustomGroup : ", seeActivityCustomGroup)}
      {console.log("seeActivityBlockGroup : ", seeActivityBlockGroup)}
    </div>
  );
};

export default Privacy;
