import React, { useState, useEffect } from "react";
import search from "../../../Assets/vectors/users/search.svg";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
const Piece = () => {
  
  const [allPieces, setAllPieces] = useState(null);
  useEffect(() => {
    fetchPiecesExchanged();
  }, []);

  const fetchPiecesExchanged = () => {
    const urlPieces = `${routes.routes.back_end.admin.puzzles.get_pieces_exchanged.url}`;
    const methodPieces = `${routes.routes.back_end.admin.puzzles.get_pieces_exchanged.method}`;
    console.log("url : ", urlPieces);
    console.log("method : ", methodPieces);

    ApiService.fetchApiData(urlPieces, methodPieces, "puzzle")
      .then((result) => {
        console.log("result : ", result);
        setAllPieces(result);
      })
      .catch(() => {
        console.log("error : ");
      });
  };
  return (
    <div className="flex flex-fd-c  transactions">
      <div className="flex users_select flex-fd-c flex-js-fs">
        <div className="flex users_select_content">
          <div className="users_select_item users_select_tab-active">
            <span
              //onClick={() => handleTab("transactions")}
              className="users_select_item_text"
            >
              All Pieces
            </span>
          </div>
        </div>
        <div className="flex transactions_select_content_down">
          <div className="flex flex-js-fs transactions_select_content_down_line"></div>
          <div className="flex flex-js-fs transactions_select_content_down_input">
            <input
              className="transactions_select_content_down_input_input"
              placeholder="search in this board"
            />
            <img
              className="transactions_select_content_down_input_img"
              src={search}
              alt="search"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-fd-c">
        <div className="puzzles_header">
          <div className="flex puzzles_header_row">
            <div className="puzzles_header_row_market">Puzzle</div>
            <div className="puzzles_header_row_market">Piece</div>
            <div className="puzzles_header_row_market">User</div>
            <div className="puzzles_header_row_market">Delivery Address</div>
            <div className="puzzles_header_row_market">Collection Point</div>
          </div>
        </div>

        {allPieces &&
          allPieces.map((value) => {
            console.log("value : ", value);
            return (
              <>
                <div className="flex flex-fd-c transactions_list">
                  <div className="flex transactions_list_row flex-js-fs">
                    <div className="users_list_row_text puzzles_header_row_market resize transactions_list_row_first-piece">
                      {value.puzzle}
                    </div>
                    <div className="users_list_row_text puzzles_header_row_market resize">
                      {value.pieces}
                    </div>
                    <div className="users_list_row_text puzzles_header_row_market resize">
                      {value.user}
                    </div>
                    <div className="users_list_row_text puzzles_header_row_market resize">
                      {value.delivery_address}
                    </div>
                    <div className="users_list_row_text puzzles_header_row_market resize">
                      null
                    </div>
                  </div>
                  <div className="users_list_line"></div>
                </div>
              </>
            );
          })}
      </div>
      {console.log("all pieces : ", allPieces)}
    </div>
  );
};

export default Piece;
